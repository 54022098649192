export const codes = {
  detail: (type: string) => (type === 'solution' ? `v1/solutions` : `v1/codes/${type}`),
  //   pit: 'v1/codes/pit-cdes', // 측량점 코드
  //   pip: 'v1/codes/pip-cdes', //파이프(관로) 코드
  //   mop: 'v1/codes/mop-cdes', //재질 코드
  //   facilityKinds: 'v1/codes/facility-kinds', //관종 코드
  //   sba: 'v1/codes/sba-cdes', // 맨홀 종류 코드
  //   pve: 'v1/codes/pve-cdes', // 밸브 종류 코드
  //   pfr: 'v1/codes/pfr-cdes', // 소화전 코드
  //   for: 'v1/codes/for-cdes', // 맨홀 형태 코드
  //   tooltip_pipe: 'v1/codes/pipe-tooltips', //파이프 툴팁 코드
  //   tooltip_point: 'v1/codes/point-tooltips', // 측량점 툴팁 코드
};

export const calendar = {
  all: 'v1/calendar', //대시보드/작업일보 - 캘린더에 데이터 있는 날짜 표시
  facility_point: 'v1/survey/calendar/facility/point', // 측량성과 - 캘린더에 관종별 데이터 있는 날짜(포인트)
  facility_pipe: 'v1/survey/calendar/facility/pipe', // 측량성과 - 캘린더에 관종별 데이터 있는 날짜(파이프)
};

export const sites = {
  all: `v1/survey/sites`,
  add: `v1/sites`,
  detail: (siteId: string) => `v1/sites/${siteId}`, //현장 - 시공 정보 조회 (construction_info)
};

export const statistics = {
  process_rate: 'v1/statistics/process-rate', //대시보드 - 전체 공정률
  facilityKind_process_rate: 'v1/statistics/facility-kinds/process-rate', // 대시보드 - 관별 공정률
  mopCde_diameters: 'v1/statistics/mop-cdes/diameters', //전체통계 - 재질/관경별 총 관수량 & 시공물량
  mopCde_dates_diameters: 'v1/statistics/mop-cdes/dates/diameters', //전체통계 - 재질/일자/관경별 관수량 & 시공물량
  pipCde_diameters: 'v1/statistics/facility-kinds/pip-cdes/diameters', // 대시보드 - 날짜까지 누적된 관종/유형/구분/관경 별 수량 및 누적길이
  daily_construction: 'v1/statistics/daily-construction', // 작업일보 일일 시공 통계
};

export const points = {
  wtl: {
    all: `v1/wtl-points`, //2.5D 지도 상수 측점을 조회 (모든 측점)
    jointDetail: (pointId: string) => `v1/wtl-joints/${pointId}`, // 상수 연결부 상세정보
    holeDetail: (pointId: string) => `v1/wtl-holes/${pointId}`, // 상수 맨홀 상세정보
    valveDetail: (pointId: string) => `v1/wtl-valves/${pointId}`, // 상수 밸브 상세정보
    fireDetail: (pointId: string) => `v1/wtl-fires/${pointId}`, //  상수 소화전 상세정보

    survey: {
      all: `v1/survey/wtl-points`, //상수 전체 측점 정보 조회 및 삭제
      date: `v1/survey/wtl-points/date`, //측량날짜로 상수 측점들을 조회 및 삭제
      bbox: `v1/survey/wtl-points/bbox/wtl-pipes`, //측점 기준 bbox 안에 있는 파이프 조회
      joints: `v1/survey/wtl-joints`, // 측량성과 - 상수 연결부 조회
      holes: `v1/survey/wtl-holes`, // 측량성과 - 상수 맨홀 조회
      valves: `v1/survey/wtl-valves`, // 측량성과 - 상수 밸브 조회
      fires: `v1/survey/wtl-fires`, // 측량성과 - 상수 소화전 조회
    },
  },
  wst: {
    all: `v1/wst-points`, //2.5D 지도 오수 측점을 조회 (모든 측점)
    jointDetail: (pointId: string) => `v1/wst-joints/${pointId}`, // 오수 연결부 상세정보
    holeDetail: (pointId: string) => `v1/wst-holes/${pointId}`, // 오수 맨홀 상세정보
    valveDetail: (pointId: string) => `v1/wst-valves/${pointId}`, // 오수 밸브 상세정보

    survey: {
      all: `v1/survey/wst-points`, //오수 전체 측점 정보 조회 및 삭제
      date: `v1/survey/wst-points/date`, //측량날짜로 오수 측점들을 조회 및 삭제
      bbox: `v1/survey/wst-points/bbox/wst-pipes`, //측점 기준 bbox 안에 있는 파이프 조회
      joints: `v1/survey/wst-joints`, // 측량성과 - 오수 연결부 조회
      holes: `v1/survey/wst-holes`, // 측량성과 - 오수 맨홀 조회
      valves: `v1/survey/wst-valves`, // 측량성과 - 오수 밸브 조회
    },
  },
  rnw: {
    all: `v1/rnw-points`, //2.5D 지도 우수 측점을 조회 (모든 측점)
    jointDetail: (pointId: string) => `v1/rnw-joints/${pointId}`, // 우수 연결부 상세정보
    holeDetail: (pointId: string) => `v1/rnw-holes/${pointId}`, // 우수 맨홀 상세정보
    valveDetail: (pointId: string) => `v1/rnw-valves/${pointId}`, // 우수 밸브 상세정보

    survey: {
      all: `v1/survey/rnw-points`, //우수 전체 측점 정보 조회 및 삭제
      date: `v1/survey/rnw-points/date`, //측량날짜로 우수 측점들을 조회 및 삭제
      bbox: `v1/survey/rnw-points/bbox/rnw-pipes`, //측점 기준 bbox 안에 있는 파이프 조회
      joints: `v1/survey/rnw-joints`, // 측량성과 - 우수 연결부 조회
      holes: `v1/survey/rnw-holes`, // 측량성과 - 우수 맨홀 조회
      valves: `v1/survey/rnw-valves`, // 측량성과 - 우수 밸브 조회
    },
  },
  elt: {
    all: `v1/elt-points`, //2.5D 지도 전기 측점을 조회 (모든 측점)
    jointDetail: (pointId: string) => `v1/elt-joints/${pointId}`, // 전기 연결부 상세정보
    holeDetail: (pointId: string) => `v1/elt-holes/${pointId}`, // 전기 맨홀 상세정보
    valveDetail: (pointId: string) => `v1/elt-valves/${pointId}`, // 전기 밸브 상세정보

    survey: {
      all: `v1/survey/elt-points`, //전기 전체 측점 정보 조회 및 삭제
      date: `v1/survey/elt-points/date`, //측량날짜로 전기 측점들을 조회 및 삭제
      bbox: `v1/survey/elt-points/bbox/elt-pipes`, //측점 기준 bbox 안에 있는 파이프 조회
      joints: `v1/survey/elt-joints`, // 측량성과 - 전기 연결부 조회
      holes: `v1/survey/elt-holes`, // 측량성과 - 전기 맨홀 조회
      valves: `v1/survey/elt-valves`, // 측량성과 - 전기 밸브 조회
    },
  },
  gas: {
    all: `v1/gas-points`, //2.5D 지도 가스 측점을 조회 (모든 측점)
    jointDetail: (pointId: string) => `v1/gas-joints/${pointId}`, // 가스 연결부 상세정보
    holeDetail: (pointId: string) => `v1/gas-holes/${pointId}`, // 가스 맨홀 상세정보
    valveDetail: (pointId: string) => `v1/gas-valves/${pointId}`, // 가스 밸브 상세정보

    survey: {
      all: `v1/survey/gas-points`, //가스 전체 측점 정보 조회 및 삭제
      date: `v1/survey/gas-points/date`, //측량날짜로 가스 측점들을 조회 및 삭제
      bbox: `v1/survey/gas-points/bbox/elt-pipes`, //측점 기준 bbox 안에 있는 파이프 조회
      joints: `v1/survey/gas-joints`, // 측량성과 - 가스 연결부 조회
      holes: `v1/survey/gas-holes`, // 측량성과 - 가스 맨홀 조회
      valves: `v1/survey/gas-valves`, // 측량성과 - 가스 밸브 조회
    },
  },
};

export const pipes = {
  wtl: {
    all: `v1/wtl-pipes`,
    survey: {
      all: `v1/survey/wtl-pipes`,
      date: `v1/survey/wtl-pipes/date`, //측량날짜로 상수 측점들을 조회 및 삭제
    },
  },
  wst: {
    all: `v1/wst-pipes`,
    survey: {
      all: `v1/survey/wst-pipes`,
      date: `v1/survey/wst-pipes/date`, //측량날짜로 오수 측점들을 조회 및 삭제
    },
  },
  rnw: {
    all: `v1/rnw-pipes`,
    survey: {
      all: `v1/survey/rnw-pipes`,
      date: `v1/survey/rnw-pipes/date`, //측량날짜로 우수 측점들을 조회 및 삭제
    },
  },
  elt: {
    all: `v1/elt-pipes`,
    survey: {
      all: `v1/survey/elt-pipes`,
      date: `v1/survey/elt-pipes/date`, //측량날짜로 전기 측점들을 조회 및 삭제
    },
  },
  gas: {
    all: `v1/gas-pipes`,
    survey: {
      all: `v1/survey/gas-pipes`,
      date: `v1/survey/gas-pipes/date`, //측량날짜로 가스 측점들을 조회 및 삭제
    },
  },
};

export const files = {
  addCsv: `v1/survey/files/csv-upload`, // CSV 업로드 TODO 삭제예정
  checkCsv: 'v1/survey/files/csv-check', // CSV 체크
  registrationCsv: 'v1/survey/files/csv-registration', // CSV 체크후 전송
  getImages: `v1/files/images`, // 측점에 대한 이미지를 조회
  checkImages: `v1/survey/files/image-check`, // 이미지 체크
  addImages: `v1/files/images`, // 이미지 여러개 등록
  addImage: `v1/files/image`, // 이미지 1개씩 등록
  deleteImage: (imageId: string) => `v1/files/images/${imageId}`, // 측점에 대한 이미지를 삭제
  facilities: (siteId: string) => `v1/files/site/${siteId}/export/facilities`,
  report: (siteId: string) => `v1/files/site/${siteId}/export/construction-report`,
};

export const workLogs = {
  points: 'v1/work-logs/points',
  pipes: 'v1/work-logs/pipes',
  export: 'v1/work-logs/export',
};

export const auth = {
  login: 'v1/login',
  addCompany: `v1/member/company`, // 회사+관리자 추가
  addUser: `v1/member/user`, // 사용자 추가
  getCompanies: `v1/member/companies`, //회사 목록 조회
  companyDetail: (companyId) => `v1/member/companies/${companyId}`, // 회사 상세정보
  memberDetail: `v1/member`, //사용자 정보 조회
  memberList: `v1/members`, //사용자 목록 조회
  duplicate: 'v1/member/duplicate',
};

export const design = {
  detail: (siteId, facilityKind) => `v1/design/site/${siteId}/facility/${facilityKind}`,
};

export const smartpin = {
  pipe: 'pipes',
  pin: 'pins',
  hole: 'holes',
  list: 'pins/page',
  image: 'pins/image',
};

export const shp = {
  list: (siteId) => `v1/shp/site/${siteId}`,
};
