import React, { useState } from 'react';
import { TocDep1Props, TocDep2Props, TocProps } from './_interface';
import { useNavigate } from 'react-router-dom';
import { HeaderStyle } from './_style';
import Ico3D from '../../assets/images/ico-menu-3d.svg';
import IcoLocation from '../../assets/images/ico-menu-location.svg';
import IcoSite from '../../assets/images/ico-menu-site.svg';
import IcoLogout from '../../assets/images/ico-menu-logout.svg';
import Ico3DActive from '../../assets/images/ico-menu-3d-active.svg';
import IcoLocationActive from '../../assets/images/ico-menu-location-active.svg';
import IcoSiteActive from '../../assets/images/ico-menu-site-active.svg';
import IcoLogoutActive from '../../assets/images/ico-menu-logout-active.svg';
import { useRecoilState } from 'recoil';
import { themeState } from '../../store/Atoms';
import Confirm from './Confirm';
import PopupLanguage from '../../popups/PopupLanguage';
import { onOpen3D } from '../../util/global';
import { useTranslation } from 'react-i18next';
import PopupSiteInfo from '../../popups/PopupSiteInfo';
import { deployKey } from './2dMap/PipeInfoModal';

const Header = ({ menu = [], onDep1Click, onDep2Click, hide, onToggle, onToggleTheme }: TocProps<TocDep1Props<TocDep2Props>>) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [themeMode] = useRecoilState(themeState);
  const [language, setLanguage] = useState([
    { key: 'ko', name: 'Korean', active: true },
    { key: 'en', name: 'English', active: false },
    { key: 'vn', name: 'Vietnam', active: false },
    { key: 'id', name: 'Indonesia', active: false },
  ]);
  const onLinkMap = () => {
    navigate('/');
  };

  //팝업 목록
  const [popupList, setPopupList] = useState({
    language: false,
    logout: false,
    map3D: false,
    siteInfo: false,
  });

  //팝업 on/off
  const onTogglePopup = (key: 'language' | 'logout' | 'map3D' | 'siteInfo') => {
    setPopupList({ ...popupList, [key]: !popupList[key] });
  };

  //로그아웃 버튼 클릭
  const onLogOut = () => {
    sessionStorage.clear();
    window.location.href = '/';
  };

  return (
    <>
      <HeaderStyle.Wrap className={hide ? 'hide' : undefined}>
        <HeaderStyle.Toggle type={'button'} onClick={onToggle}>
          <span className={'hidden'}>보이기/숨기기</span>
        </HeaderStyle.Toggle>
        <HeaderStyle.Logo onClick={() => navigate('/dashboard')}></HeaderStyle.Logo>
        <HeaderStyle.List className={themeMode === 'light' ? 'cScroll light' : 'cScroll'}>
          {menu
            .filter(({ hide }) => !hide)
            .map(({ active, name, dep2, ico, activeIco, admin }, index) => {
              return (
                <HeaderStyle.Item key={index} themeMode={themeMode} ico={ico} activeIco={activeIco} className={(active ? 'active ' : '') + (dep2 ? 'hasDep ' : '')}>
                  {/*className={active && dep2 ? 'active hasDep' : active && !dep2 ? 'active' : !active && dep2 ? 'hasDep' : undefined}>*/}
                  <span onClick={() => onDep1Click(index)}>{name ? t(name) : name}</span>
                  {dep2?.length && (
                    <HeaderStyle.Dep>
                      {dep2.map(({ name, active, src }, idx) => {
                        return (
                          <HeaderStyle.DepItem
                            key={idx}
                            className={active ? 'active' : undefined}
                            onClick={() => {
                              onDep2Click(index, idx);
                            }}
                          >
                            {name}
                          </HeaderStyle.DepItem>
                        );
                      })}
                    </HeaderStyle.Dep>
                  )}
                </HeaderStyle.Item>
              );
            })}
        </HeaderStyle.List>
        <HeaderStyle.Bottom>
          <HeaderStyle.BottomBox>
            <span>NEW PAGES</span>
            <HeaderStyle.BottomBtn type={'button'} themeMode={themeMode} onClick={() => onTogglePopup('map3D')} $ico={Ico3D} $icoActive={Ico3DActive}>
              {t('3D지도')}
            </HeaderStyle.BottomBtn>
          </HeaderStyle.BottomBox>
          <HeaderStyle.BottomBox>
            <HeaderStyle.BottomBtn type={'button'} themeMode={themeMode} onClick={() => onTogglePopup('language')} $ico={IcoLocation} $icoActive={IcoLocationActive}>
              Language
            </HeaderStyle.BottomBtn>

            <HeaderStyle.BottomBtn type={'button'} themeMode={themeMode} onClick={() => onTogglePopup('siteInfo')} $ico={IcoSite} $icoActive={IcoSiteActive}>
              Info
            </HeaderStyle.BottomBtn>
            <HeaderStyle.BottomBtn type={'button'} themeMode={themeMode} onClick={() => onTogglePopup('logout')} $ico={IcoLogout} $icoActive={IcoLogoutActive}>
              Logout
            </HeaderStyle.BottomBtn>
            <HeaderStyle.ChangeModeBtn type={'button'} className={themeMode === 'dark' ? 'active' : undefined} onClick={onToggleTheme}>
              <span>Light</span>
              <span>Dark</span>
            </HeaderStyle.ChangeModeBtn>
          </HeaderStyle.BottomBox>
        </HeaderStyle.Bottom>
      </HeaderStyle.Wrap>
      {popupList.logout ? <Confirm desc={t('로그아웃여부')} onClickCancel={() => onTogglePopup('logout')} onClickSubmit={onLogOut} /> : null}
      {popupList.map3D ? (
        <Confirm
          desc={t('3D지도이동여부')}
          onClickCancel={() => onTogglePopup('map3D')}
          onClickSubmit={() => {
            onOpen3D(`${deployKey === 'origin' ? 'd2man' : 'd2dev'}://?token=${sessionStorage.getItem('token')}&siteId=${sessionStorage.getItem('siteId')}&type=&id=&facilityKind=`);
            onTogglePopup('map3D');
          }}
        />
      ) : null}
      {popupList.language ? <PopupLanguage language={language} setLanguage={setLanguage} onClickCancel={() => onTogglePopup('language')} onClickSubmit={() => onTogglePopup('language')} /> : null}
      {popupList.siteInfo ? <PopupSiteInfo onClose={() => onTogglePopup('siteInfo')} /> : null}
    </>
  );
};

export default Header;
