import React, { SetStateAction, useEffect, useState } from 'react';
import Header from './Header';
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { TocDep1Props, TocDep2Props } from './_interface';
import IcoMenu01 from '../../assets/images/ico-menu-01.svg';
import IcoMenu02 from '../../assets/images/ico-menu-02.svg';
import IcoMenu03 from '../../assets/images/ico-menu-03.svg';
import IcoMenu04 from '../../assets/images/ico-menu-04.svg';
import IcoMenu01Active from '../../assets/images/ico-menu-01-active.svg';
import IcoMenu02Active from '../../assets/images/ico-menu-02-active.svg';
import IcoMenu03Active from '../../assets/images/ico-menu-03-active.svg';
import IcoMenu04Active from '../../assets/images/ico-menu-04-active.svg';
import { ContainerStyle } from './_style';

import { useRecoilState } from 'recoil';
import { constructionInfoState, siteListState, solutionState } from 'store/Atoms';
import { qsData, request } from '../../service/api/common';
import { useTranslation } from 'react-i18next';
import query from 'hooks/RQuery/utils';
import Select from '../atoms/Select';
import { SelectProps } from '../../pages/_interface';
import SearchSite from '../organizations/SearchSite';
import { allFacilityList, menuObject } from '../../util/global';

interface ContainerProps {
  onToggleTheme: () => void;
}

const Container = ({ onToggleTheme }: ContainerProps) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const code = query.codes.useCodes();
  const myInfo = query.auths.useMyInfo();
  const [browserSize, setBrowserSize] = useState<{ hide: boolean; mapWidth: boolean; orgWidth: boolean }>({
    hide: false,
    mapWidth: false,
    orgWidth: false,
  });

  const [menu, setMenu] = useState<TocDep1Props<TocDep2Props>[]>(
    sessionStorage.getItem('auth') === 'ROLE_MASTER'
      ? menuObject.SUPER_ADMIN
      : sessionStorage.getItem('auth') === 'ROLE_ADMIN'
        ? menuObject.ADMIN.DEFAULT
        : sessionStorage.getItem('auth') === 'ROLE_MANAGER'
          ? menuObject.MANAGER.DEFAULT
          : sessionStorage.getItem('auth') === 'ROLE_USER'
            ? menuObject.USER.DEFAULT
            : [],
  );

  const [info, setInfo] = useRecoilState(constructionInfoState);
  const [siteList, setSiteList] = useRecoilState(siteListState);
  const [solutionList, setSolutionList] = useRecoilState(solutionState);
  const constructionInfo = query.sites.useSiteDetail({ siteId: siteList.filter((i) => i.active)[0]?.value || '' });
  const [popupList, setPopupList] = useState({
    siteInfo: false,
  });

  const facilitys = code?.data?.FACILITY; // 시설물 코드

  // 전체 시설물 리스트 목록 (isChecked 추가)
  useEffect(() => {
    if (facilitys) {
      sessionStorage.setItem('facilitys', JSON.stringify(facilitys));
    } else {
      sessionStorage.setItem('facilitys', JSON.stringify(allFacilityList));
    }
  }, [facilitys]);

  //사용자의 언어 적용
  useEffect(() => {
    const newArr = [...menu];

    setMenu(newArr);
  }, [i18n.language, myInfo.data]);

  useEffect(() => {
    if (myInfo.isSuccess) {
      if (myInfo.data.country === 'VN') {
        i18n.changeLanguage('vn');
      } else if (myInfo.data.country === 'US') {
        i18n.changeLanguage('en');
      } else if (myInfo.data.country === 'ID') {
        i18n.changeLanguage('id');
      }
    }
  }, [myInfo.data]);

  useEffect(() => {
    if (constructionInfo.data) {
      const data = constructionInfo.data;
      setInfo(data);
    }
  }, [constructionInfo.data]);

  //1뎁스 클릭
  const onDep1Click = (num: number) => {
    let newArr = [...menu.filter(({ hide }) => !hide)];
    if (menu[num].dep2) {
      //2뎁스가 있는 메뉴일때
      newArr.map((i, index) => {
        //클릭한 것 active 토글 | 나머지 false
        index === num ? (newArr[index].active = !newArr[index].active) : (newArr[index].active = false);
      });
    } else {
      //2뎁스가 없는 메뉴일때
      newArr.map(({ dep2, src }, index) => {
        //클릭한 것 active | 나머지 false
        index === num ? (newArr[index].active = true) : (newArr[index].active = false);

        //모든 2뎁스 false
        dep2?.map(({ active, src }, idx) => {
          dep2[idx].active = false;
        });

        //선택한 메뉴경로 이동
        if (index === num && src) {
          navigate(src);
        }
      });
    }
    setMenu(newArr);
  };

  //2뎁스 클릭
  const onDep2Click = (num1: number, num2: number) => {
    let newArr = [...menu];
    newArr.map(({ dep2 }, index) => {
      //해당 메뉴의 1뎁스 active | 그 외 1뎁스 false
      index === num1 ? (newArr[index].active = true) : (newArr[index].active = false);

      dep2?.map(({ active, src }, idx) => {
        if (num1 === index && num2 === idx) {
          // 선택한 2뎁스 메뉴경로 이동 및 active
          dep2[idx].active = true;
          navigate(dep2[idx].src);
        } else {
          // 그 외 2뎁스 false
          dep2[idx].active = false;
        }
      });
    });
    setMenu(newArr);
  };

  const onToggle = () => {
    setBrowserSize({
      ...browserSize,
      hide: !browserSize.hide,
    });
  };
  const onLoadSize = (key?: string) => {
    if (key === 'mapWidth') {
      setBrowserSize({
        hide: browserSize.hide,
        mapWidth: true,
        orgWidth: false,
      });
    } else if (key === 'orgWidth') {
      setBrowserSize({
        hide: browserSize.hide,
        mapWidth: false,
        orgWidth: true,
      });
    } else {
      setBrowserSize({
        hide: browserSize.hide,
        mapWidth: false,
        orgWidth: false,
      });
    }
  };

  const onTogglePopup = (key: 'siteInfo') => {
    setPopupList({ ...popupList, [key]: !popupList[key] });
  };

  //현장 변경
  const onChangeSite = (e) => {
    setSiteList((prev) => {
      return prev.map((i) => {
        return {
          ...i,
          active: i.value === e.target.ariaValueText,
        };
      });
    });
    sessionStorage.setItem('siteId', e.target.ariaValueText);
  };

  // 현재 path 맞게 메뉴 active
  useEffect(() => {
    const pathName = location.pathname;
    let newArr = [...menu];
    newArr.map((i) => {
      pathName.includes(i.id || '') ? (i.active = true) : (i.active = false);
    });
    setMenu(newArr);
  }, [location.pathname]);

  //쿼리스트링으로 해당 정보들이 있을때 자동으로 로그인 및 페이지 이동
  useEffect(() => {
    if (qsData().token) {
      sessionStorage.setItem('token', qsData().token);
      sessionStorage.setItem('siteId', qsData().siteId);
      request('auth')
        .get('/v1/member', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: qsData().token,
          },
        })
        .then(({ data }) => {
          sessionStorage.setItem('auth', data.data.auth);
          sessionStorage.setItem('solution', data.data.solutions);
          sessionStorage.setItem('projection', data.data.siteInfo.filter((i) => i.siteId === qsData().siteId)[0].projection);
          const newArr: SelectProps[] = [];
          data.data.siteInfo.map((i, index) => {
            newArr.push({ text: i.siteName, value: i.siteId, active: i.siteId === qsData().siteId, facilityKinds: i.facilityKinds });
          });
          setSiteList(newArr);
        });
    }
  }, []);

  useEffect(() => {
    if (myInfo.data) {
      const newArr: SelectProps[] = [];
      myInfo.data.siteInfo.map((i, index) => {
        // newArr.push({ text: i.siteName, value: i.siteId, active: i.siteId === sessionStorage.getItem('siteId'), facilityKinds: i.facilityKinds });
        newArr.push({ ...i, text: i.siteName, value: i.siteId, active: myInfo.data.siteInfo.length === 1 ? true : i.siteId === sessionStorage.getItem('siteId') });
      });
      if (myInfo.data.siteInfo.length === 1) {
        sessionStorage.setItem('siteId', String(newArr[0].value));
        sessionStorage.setItem('projection', String(newArr[0].projection));
      }
      setSiteList(newArr);

      const newArr2: string[] = [];
      myInfo.data.solutions.map((i) => {
        newArr2.push(i);
      });
      setSolutionList(newArr2);
    }
  }, [myInfo.data]);

  //설계등록, 사업장관리, 측량성과등록은 현장선택 셀렉트박스를 보여주지 않는다
  const isSiteSelectOff = location.pathname.includes('design') || location.pathname.includes('workplace') || location.pathname.includes('survey');

  return (
    <>
      <ContainerStyle.Contain>
        <Header menu={menu} hide={browserSize.hide} onDep1Click={onDep1Click} onDep2Click={onDep2Click} onToggle={onToggle} onToggleTheme={onToggleTheme} />
        <ContainerStyle.Wrap>
          <ContainerStyle.Content className={`${browserSize.hide ? 'full' : ''} ${browserSize.mapWidth ? 'mapWidth' : ''} ${browserSize.orgWidth ? 'orgWidth' : ''}`}>
            {/* 페이지 제목, 버전, 현장정보 영역*/}
            <ContainerStyle.TitleArea>
              <ContainerStyle.Title>{t(menu.filter((i) => i.active)[0]?.name || '')}</ContainerStyle.Title>
              <ContainerStyle.InfoArea>
                {sessionStorage.getItem('auth') !== 'ROLE_MASTER' && !isSiteSelectOff ? <SearchSite /> : null}
                {/*{sessionStorage.getItem('auth') !== 'ROLE_MASTER' ? <Select option={siteList} width={600} onChange={(e) => onChangeSite(e)} value={siteList.filter(({ active }) => active)[0]?.text || ''} /> : null}*/}
                {/*<ContainerStyle.Version>Digital Twin X V0.1</ContainerStyle.Version>*/}
              </ContainerStyle.InfoArea>
            </ContainerStyle.TitleArea>
            <ContainerStyle.Inner>
              {/* 중첩 route */}
              {code.isSuccessAll ? <Outlet /> : null}
              {/* <Routes>
                        <Route path={"/dashboard"} element={<Dashboard />} />
                        <Route path={"/"} element={<Map />} />
                        <Route path={"/map2"} element={<Map2 />} />
                        <Route path={"/report"} element={<Report />} />
                     </Routes> */}
            </ContainerStyle.Inner>
          </ContainerStyle.Content>
        </ContainerStyle.Wrap>
      </ContainerStyle.Contain>
    </>
  );
};

export default Container;
