import * as wtl from './wtl';
import * as wst from './wst';
import * as rnw from './rnw';
import * as elt from './elt';
import * as gas from './gas';

/* wtl,wst,rnw 에서 가져와서, 모아서 내보내기가 필요할때
   컴포넌트에서는 한줄로 표현 (쿼리 구분 파라미터 전달 facility, type 등 - 여기서 구분)
*/

/* 
   관 종류에 따라 [옵션] 리턴 
   모든 측점 조회
 */
export function getPointsOptions({ siteId, facility }) {
  const queryOptions = {
    WATER: facility === 'WATER' && wtl.getPointsOptions({ siteId }), // 상수, 모든 포인트 조회
    WASTE: facility === 'WASTE' && wst.getPointsOptions({ siteId }), // 오수, 모든 포인트 조회
    RAIN: facility === 'RAIN' && rnw.getPointsOptions({ siteId }), // 우수, 모든 포인트 조회
    GAS: facility === 'GAS' && gas.getPointsOptions({ siteId }),
    NETWORK: { queryKey: [], queryFn: null },
    ELECTRIC: facility === 'ELECTRIC' && elt.getPointsOptions({ siteId }),
    OIL: { queryKey: [], queryFn: null },
    HEAT: { queryKey: [], queryFn: null },
  };

  return queryOptions[facility];
}

/* 
   facility 조건을 걸어줘야 나머지 쿼리 요청하지 않음. 

   관 종류에 따라 [쿼리] 리턴 (1개씩)
   모든 측점 조회
 */
export function getPointsQuery({ siteId, facility }) {
  const query = {
    WATER: facility === 'WATER' && wtl.usePoints({ siteId }), // 상수, 모든 포인트 조회
    WASTE: facility === 'WASTE' && wst.usePoints({ siteId }), // 오수, 모든 포인트 조회
    RAIN: facility === 'RAIN' && rnw.usePoints({ siteId }), // 우수, 모든 포인트 조회
    GAS: facility === 'GAS' && gas.usePoints({ siteId }),
    NETWORK: {},
    ELECTRIC: facility === 'ELECTRIC' && elt.usePoints({ siteId }),
    OIL: {},
    HEAT: {},
  };
  return query[facility];
}

/* 
   관 종류에 따라 [쿼리] 리턴 
   측점 상세 정보 조회 
 */
export function getPointDetailQuery({ facility = 'WATER', type, pointId }) {
  const facilityQuerys = {
    WATER: facility === 'WATER' && wtl.usePointDetail({ type, pointId }), // 상수, 측점 상세 조회
    WASTE: facility === 'WASTE' && wst.usePointDetail({ type, pointId }), // 오수, 측점 상세 조회
    RAIN: facility === 'RAIN' && rnw.usePointDetail({ type, pointId }), // 우수, 측점 상세 조회
    GAS: facility === 'GAS' && gas.usePointDetail({ type, pointId }),
    NETWORK: {},
    ELECTRIC: facility === 'ELECTRIC' && elt.usePointDetail({ type, pointId }),
    OIL: {},
    HEAT: {},
  };
  const fakeQuery = { data: [], isSuccess: false }; // 일치하는 쿼리가 없을때 리턴
  return facilityQuerys[facility] || fakeQuery;
}

// * 관 종류에 따른 측량성과등록 테이블 조회(측점) * //
export function getSurveyPointsQuery({ type, siteId, pageSize, pageNum, sort, startDate, endDate, ptNum, facility }) {
  const facilityQuerys = {
    WATER: facility === 'WATER' && wtl.useSurveyPoints({ type, siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }), // 상수
    WASTE: facility === 'WASTE' && wst.useSurveyPoints({ type, siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }), // 오수
    RAIN: facility === 'RAIN' && rnw.useSurveyPoints({ type, siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }), // 우수
    GAS: facility === 'GAS' && gas.useSurveyPoints({ type, siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }),
    NETWORK: {},
    ELECTRIC: facility === 'ELECTRIC' && elt.useSurveyPoints({ type, siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }),
    OIL: {},
    HEAT: {},
  };

  const fakeQuery = { data: [], isSuccess: false }; // 일치하는 쿼리가 없을때 리턴
  return facilityQuerys[facility] || fakeQuery;
}

// 측점 최초 등록 여부 확인
export function getSurveyPointsFirstQuery({ siteId, facility }) {
  const facilityQuerys = {
    WATER: facility === 'WATER' && wtl.useSurveyPointsFirst({ siteId, facility, pageSize: 10, pageNum: 1, sort: 'DESC', startDate: null, endDate: null, ptNum: null }), // 상수
    WASTE: facility === 'WASTE' && wst.useSurveyPointsFirst({ siteId, facility, pageSize: 10, pageNum: 1, sort: 'DESC', startDate: null, endDate: null, ptNum: null }), // 오수
    RAIN: facility === 'RAIN' && rnw.useSurveyPointsFirst({ siteId, facility, pageSize: 10, pageNum: 1, sort: 'DESC', startDate: null, endDate: null, ptNum: null }), // 우수
    GAS: facility === 'GAS' && gas.useSurveyPointsFirst({ siteId, facility, pageSize: 10, pageNum: 1, sort: 'DESC', startDate: null, endDate: null, ptNum: null }),
    NETWORK: {},
    ELECTRIC: facility === 'ELECTRIC' && elt.useSurveyPointsFirst({ siteId, facility, pageSize: 10, pageNum: 1, sort: 'DESC', startDate: null, endDate: null, ptNum: null }),
    OIL: {},
    HEAT: {},
  };

  const fakeQuery = { data: [], isSuccess: false }; // 일치하는 쿼리가 없을때 리턴
  return facilityQuerys[facility] || fakeQuery;
}

// * 관 종류별 측점 기준 bbox(선택삭제) * //
export function getSurveyBbox({ pointIds, siteId, facility }) {
  const facilityQuerys = {
    WATER: facility === 'WATER' && wtl.useSurveyBbox({ pointIds, siteId }), // 상수
    WASTE: facility === 'WASTE' && wst.useSurveyBbox({ pointIds, siteId }), // 오수
    RAIN: facility === 'RAIN' && rnw.useSurveyBbox({ pointIds, siteId }), // 우수
    GAS: facility === 'GAS' && gas.useSurveyBbox({ pointIds, siteId }), // 우수,
    NETWORK: {},
    ELECTRIC: facility === 'ELECTRIC' && elt.useSurveyBbox({ pointIds, siteId }), // 우수,
    OIL: {},
    HEAT: {},
  };

  const fakeQuery = { data: [], isSuccess: false }; // 일치하는 쿼리가 없을때 리턴
  return facilityQuerys[facility] || fakeQuery;
}

// 측점 날짜별
export function getSurveyPointsByDateQuery({ siteId, date, facility, workDate }) {
  const facilityQuerys = {
    WATER: facility === 'WATER' && wtl.useSurveyPointsByDate({ siteId, date, workDate }), // 상수, 측점 - 날짜별 조회
    WASTE: facility === 'WASTE' && wst.useSurveyPointsByDate({ siteId, date, workDate }), // 오수, 측점 - 날짜별 조회
    RAIN: facility === 'RAIN' && rnw.useSurveyPointsByDate({ siteId, date, workDate }), // 우수, 측점 - 날짜별 조회
    GAS: facility === 'GAS' && gas.useSurveyPointsByDate({ siteId, date, workDate }),
    NETWORK: {},
    ELECTRIC: facility === 'ELECTRIC' && elt.useSurveyPointsByDate({ siteId, date, workDate }),
    OIL: {},
    HEAT: {},
  };
  const fakeQuery = { data: [], isSuccess: false }; // 일치하는 쿼리가 없을때 리턴
  return facilityQuerys[facility] || fakeQuery;
}

//측점 선택삭제
export function deleteSurveyPoints({ siteId, pointIds, facility }) {
  const facilityMutates = {
    WATER: facility === 'WATER' && wtl.useSurveyDeletePoints({ pointIds: pointIds, siteId: siteId }),
    WASTE: facility === 'WASTE' && wst.useSurveyDeletePoints({ pointIds: pointIds, siteId: siteId }),
    RAIN: facility === 'RAIN' && rnw.useSurveyDeletePoints({ pointIds: pointIds, siteId: siteId }),
    GAS: facility === 'GAS' && gas.useSurveyDeletePoints({ pointIds: pointIds, siteId: siteId }),
    NETWORK: {},
    ELECTRIC: facility === 'ELECTRIC' && elt.useSurveyDeletePoints({ pointIds: pointIds, siteId: siteId }),
    OIL: {},
    HEAT: {},
  };
  return facilityMutates[facility];
}

//측점 날짜삭제
export function deleteSurveyPointsByDate({ siteId, date, facility }) {
  const facilityMutates = {
    WATER: facility === 'WATER' && wtl.useSurveyDeletePointsByDate({ siteId: siteId, date: date }),
    WASTE: facility === 'WASTE' && wst.useSurveyDeletePointsByDate({ siteId: siteId, date: date }),
    RAIN: facility === 'RAIN' && rnw.useSurveyDeletePointsByDate({ siteId: siteId, date: date }),
    GAS: facility === 'GAS' && gas.useSurveyDeletePointsByDate({ siteId: siteId, date: date }),
    NETWORK: {},
    ELECTRIC: facility === 'ELECTRIC' && elt.useSurveyDeletePointsByDate({ siteId: siteId, date: date }),
    OIL: {},
    HEAT: {},
  };
  return facilityMutates[facility];
}

const points = {
  wtl,
  wst,
  rnw,
  elt,
  gas,
};

export default points;
