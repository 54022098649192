import { request } from 'service/api/common';
import * as apiUrl from './apiUrl';

const mainUrl = apiUrl.points;

// [공통]
// const getPoints = async (type: string) => {
//   const { data } = await request.get(mainUrl.wtl.all);
//   return data;
// };

// 상수 측량점 관련
const wtlUrl = mainUrl.wtl;
export const wtl = {
  // 2d map (all)
  getPoints: async ({ siteId }) => {
    const { data } = await request('d2').get(wtlUrl.all, { params: { siteId } });
    return data.data;
  },

  // survey
  getSurveyPoints: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(wtlUrl.survey.all, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  getSurveyJoints: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(wtlUrl.survey.joints, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  getSurveyHoles: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(wtlUrl.survey.holes, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  getSurveyValves: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(wtlUrl.survey.valves, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  getSurveyFires: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(wtlUrl.survey.fires, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  getSurveyBbox: async ({ pointIds, siteId }) => {
    const { data } = await request('d2').get(wtlUrl.survey.bbox, {
      params: { pointIds, siteId },
      paramsSerializer: {
        indexes: null,
      },
    });
    return data.data;
  },

  getJointDetail: async (pointId) => {
    const { data } = await request('d2').get(wtlUrl.jointDetail(pointId));
    return data.data;
  },
  getHoleDetail: async (pointId) => {
    const { data } = await request('d2').get(wtlUrl.holeDetail(pointId));
    return data.data;
  },
  getValveDetail: async (pointId) => {
    const { data } = await request('d2').get(wtlUrl.valveDetail(pointId));
    return data.data;
  },
  getFireDetail: async (pointId) => {
    const { data } = await request('d2').get(wtlUrl.fireDetail(pointId));
    return data.data;
  },
  //선택 삭제
  deleteSurveyPoints: async ({ siteId, pointIds }) => {
    const { data } = await request('d2').delete(wtlUrl.survey.all, {
      params: { siteId, pointIds },
      paramsSerializer: {
        indexes: null,
      },
    });
    return data.data;
  },
  //날짜 삭제
  deleteSurveyPointsByDate: async ({ siteId, date }) => {
    const { data } = await request('d2').delete(wtlUrl.survey.date, {
      params: { siteId, date },
    });
    return data.data;
  },
  //(측량성과)날짜별 측점 조회
  getSurveyPointsByDate: async ({ siteId, date }) => {
    const { data } = await request('d2').get(wtlUrl.survey.date, {
      params: { siteId, date },
    });
    return data.data;
  },
};

// 오수 측량점 관련
const wstUrl = mainUrl.wst;
export const wst = {
  // 2d map (all)
  getPoints: async ({ siteId }) => {
    const { data } = await request('d2').get(wstUrl.all, { params: { siteId } });
    return data.data;
  },

  // survey
  getSurveyPoints: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(wstUrl.survey.all, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  getSurveyJoints: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(wstUrl.survey.joints, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  getSurveyHoles: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(wstUrl.survey.holes, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  getSurveyValves: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(wstUrl.survey.valves, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  getSurveyBbox: async ({ pointIds, siteId }) => {
    const { data } = await request('d2').get(wstUrl.survey.bbox, {
      params: { pointIds, siteId },
      paramsSerializer: {
        indexes: null,
      },
    });
    return data.data;
  },

  getJointDetail: async (pointId) => {
    const { data } = await request('d2').get(wstUrl.jointDetail(pointId));
    return data.data;
  },
  getHoleDetail: async (pointId) => {
    const { data } = await request('d2').get(wstUrl.holeDetail(pointId));
    return data.data;
  },
  getValveDetail: async (pointId) => {
    const { data } = await request('d2').get(wstUrl.valveDetail(pointId));
    return data.data;
  },
  //선택 삭제
  deleteSurveyPoints: async ({ siteId, pointIds }) => {
    const { data } = await request('d2').delete(wstUrl.survey.all, {
      params: { siteId, pointIds },
      paramsSerializer: {
        indexes: null,
      },
    });
    return data.data;
  },
  //날짜 삭제
  deleteSurveyPointsByDate: async ({ siteId, date }) => {
    const { data } = await request('d2').delete(wstUrl.survey.date, {
      params: { siteId, date },
    });
    return data.data;
  },
  //(측량성과)날짜별 측점 조회
  getSurveyPointsByDate: async ({ siteId, date }) => {
    const { data } = await request('d2').get(wstUrl.survey.date, {
      params: { siteId, date },
    });
    return data.data;
  },
};

// 우수 측량점 관련
const rnwUrl = mainUrl.rnw;
export const rnw = {
  // 2d map (all)
  getPoints: async ({ siteId }) => {
    const { data } = await request('d2').get(rnwUrl.all, { params: { siteId } });
    return data.data;
  },

  // survey
  getSurveyPoints: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(rnwUrl.survey.all, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  getSurveyJoints: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(rnwUrl.survey.joints, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  getSurveyHoles: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(rnwUrl.survey.holes, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  getSurveyValves: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(rnwUrl.survey.valves, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  getSurveyBbox: async ({ pointIds, siteId }) => {
    const { data } = await request('d2').get(rnwUrl.survey.bbox, {
      params: { pointIds, siteId },
      paramsSerializer: {
        indexes: null,
      },
    });
    return data.data;
  },

  getJointDetail: async (pointId) => {
    const { data } = await request('d2').get(rnwUrl.jointDetail(pointId));
    return data.data;
  },
  getHoleDetail: async (pointId) => {
    const { data } = await request('d2').get(rnwUrl.holeDetail(pointId));
    return data.data;
  },
  getValveDetail: async (pointId) => {
    const { data } = await request('d2').get(rnwUrl.valveDetail(pointId));
    return data.data;
  },
  //선택 삭제
  deleteSurveyPoints: async ({ siteId, pointIds }) => {
    const { data } = await request('d2').delete(rnwUrl.survey.all, {
      params: { siteId, pointIds },
      paramsSerializer: {
        indexes: null,
      },
    });
    return data.data;
  },
  //날짜 삭제
  deleteSurveyPointsByDate: async ({ siteId, date }) => {
    const { data } = await request('d2').delete(rnwUrl.survey.date, {
      params: { siteId, date },
    });
    return data.data;
  },
  //(측량성과)날짜별 측점 조회
  getSurveyPointsByDate: async ({ siteId, date }) => {
    const { data } = await request('d2').get(rnwUrl.survey.date, {
      params: { siteId, date },
    });
    return data.data;
  },
};

// 전기 측량점 관련
const eltUrl = mainUrl.elt;
export const elt = {
  // 2d map (all)
  getPoints: async ({ siteId }) => {
    const { data } = await request('d2').get(eltUrl.all, { params: { siteId } });
    return data.data;
  },

  // survey
  getSurveyPoints: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(eltUrl.survey.all, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  getSurveyJoints: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(eltUrl.survey.joints, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  getSurveyHoles: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(eltUrl.survey.holes, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  getSurveyValves: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(eltUrl.survey.valves, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  getSurveyBbox: async ({ pointIds, siteId }) => {
    const { data } = await request('d2').get(eltUrl.survey.bbox, {
      params: { pointIds, siteId },
      paramsSerializer: {
        indexes: null,
      },
    });
    return data.data;
  },

  getJointDetail: async (pointId) => {
    const { data } = await request('d2').get(eltUrl.jointDetail(pointId));
    return data.data;
  },
  getHoleDetail: async (pointId) => {
    const { data } = await request('d2').get(eltUrl.holeDetail(pointId));
    return data.data;
  },
  getValveDetail: async (pointId) => {
    const { data } = await request('d2').get(eltUrl.valveDetail(pointId));
    return data.data;
  },
  //선택 삭제
  deleteSurveyPoints: async ({ siteId, pointIds }) => {
    const { data } = await request('d2').delete(eltUrl.survey.all, {
      params: { siteId, pointIds },
      paramsSerializer: {
        indexes: null,
      },
    });
    return data.data;
  },
  //날짜 삭제
  deleteSurveyPointsByDate: async ({ siteId, date }) => {
    const { data } = await request('d2').delete(eltUrl.survey.date, {
      params: { siteId, date },
    });
    return data.data;
  },
  //(측량성과)날짜별 측점 조회
  getSurveyPointsByDate: async ({ siteId, date }) => {
    const { data } = await request('d2').get(eltUrl.survey.date, {
      params: { siteId, date },
    });
    return data.data;
  },
};

// 가스 측량점 관련
const gasUrl = mainUrl.gas;
export const gas = {
  // 2d map (all)
  getPoints: async ({ siteId }) => {
    const { data } = await request('d2').get(gasUrl.all, { params: { siteId } });
    return data.data;
  },

  // survey
  getSurveyPoints: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(gasUrl.survey.all, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  getSurveyJoints: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(gasUrl.survey.joints, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  getSurveyHoles: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(gasUrl.survey.holes, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  getSurveyValves: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(gasUrl.survey.valves, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  getSurveyBbox: async ({ pointIds, siteId }) => {
    const { data } = await request('d2').get(gasUrl.survey.bbox, {
      params: { pointIds, siteId },
      paramsSerializer: {
        indexes: null,
      },
    });
    return data.data;
  },

  getJointDetail: async (pointId) => {
    const { data } = await request('d2').get(gasUrl.jointDetail(pointId));
    return data.data;
  },
  getHoleDetail: async (pointId) => {
    const { data } = await request('d2').get(gasUrl.holeDetail(pointId));
    return data.data;
  },
  getValveDetail: async (pointId) => {
    const { data } = await request('d2').get(gasUrl.valveDetail(pointId));
    return data.data;
  },
  //선택 삭제
  deleteSurveyPoints: async ({ siteId, pointIds }) => {
    const { data } = await request('d2').delete(gasUrl.survey.all, {
      params: { siteId, pointIds },
      paramsSerializer: {
        indexes: null,
      },
    });
    return data.data;
  },
  //날짜 삭제
  deleteSurveyPointsByDate: async ({ siteId, date }) => {
    const { data } = await request('d2').delete(gasUrl.survey.date, {
      params: { siteId, date },
    });
    return data.data;
  },
  //(측량성과)날짜별 측점 조회
  getSurveyPointsByDate: async ({ siteId, date }) => {
    const { data } = await request('d2').get(gasUrl.survey.date, {
      params: { siteId, date },
    });
    return data.data;
  },
};
