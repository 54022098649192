import React, { CSSProperties, useEffect, useState } from 'react';
import styled from 'styled-components';
import IcoFly from 'assets/images/ico-toolbar-current.svg';
import IcoMove from 'assets/images/ico-toolbar-move.svg';
import IcoMoveActive from 'assets/images/ico-toolbar-move-active.svg';
import IcoSite from 'assets/images/2dMap/ico-site.svg';
import IcoConstruct from 'assets/images/2dMap/ico-construct.svg';
import { InputStyle } from '../../atoms/_style';
import { Point } from 'ol/geom';
import ObjectMapPoint from '../../../maps/wfs/Object/MapPoint';
import { isError } from 'react-query';
import { useRecoilState } from 'recoil';
import { siteListState } from '../../../store/Atoms';
import query from '../../../hooks/RQuery/utils';
import Checkbox from '../../atoms/Checkbox';

const ToolbarSt = {
  Wrap: styled.div`
    position: absolute;
    right: 16px;
    top: 76px;
    z-index: 9;
  `,
  BtnWrap: styled.div`
    width: 36px;
    border-radius: 2px;
    border: 1px solid #ccc;
    & > button {
      &:last-child {
        border-bottom: 0;
      }
    }
  `,
  ContWrap: styled.div`
    position: relative;
    &:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }
    > button {
      border-bottom: 0;
    }
  `,
  Button: styled.button`
    position: relative;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    &:not(:last-child) {
      /* margin-bottom: 2px; */
    }
    &.active {
      background-color: #cddcff;
      > .ico {
        &.move {
          background-image: url(${IcoMoveActive});
        }
      }
    }
    > .ico {
      width: 36px;
      height: 36px;
      display: inline-block;
      background-repeat: no-repeat;
      background-position: center;
      &.move {
        background-image: url(${IcoMove});
      }
      &.site {
        background-image: url(${IcoSite});
      }
      &.construct {
        background-image: url(${IcoConstruct});
      }
    }

    > .tooltip {
      transition: all 0.4s;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      bottom: 0;
      right: calc(100%);
      z-index: 1;

      width: max-content;
      height: 26px;
      padding: 0 6px;
      background-color: rgba(0, 0, 0, 0.8);
      border-radius: 4px;
      box-shadow: 3px 3px 10px 0px #00000037;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      // 삼각형
      &::after {
        content: ' ';
        position: absolute;
        z-index: 1;
        top: 50%;
        left: calc(100%);
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent transparent rgba(0, 0, 0, 0.8);
      }
    }

    &:hover {
      transition: all 0.4s;
      background-color: #eee;
      > .tooltip {
        opacity: 1;
        visibility: visible;
        right: calc(100% + 9px);
      }
    }
  `,
  ToolWrap: styled.div`
    position: relative;
  `,
  MoveCont: styled.div`
    position: absolute;
    right: 0;
    top: 0;
    background: ${(p) => p.theme.map.moveCont.bg};
    border: 1px solid ${(p) => p.theme.map.moveCont.border};
    box-sizing: border-box;
    border-radius: 2px;
    transition: 0.4s;
    visibility: hidden;
    opacity: 0;
    &.active {
      right: calc(100% + 5px);
      visibility: visible;
      opacity: 1;
    }
  `,
  MoveInner: styled.div`
    padding: 12px;
  `,
  InputWrap: styled(InputStyle.Wrap)<{ isError?: boolean }>`
    background: ${(p) => p.theme.map.moveCont.input.bg};
    box-sizing: border-box;
    border: 1px solid ${(p) => (p.isError ? '#FE0F00' : p.theme.map.moveCont.input.border)};
    border-radius: 2px;
    &:focus-within {
      border: 1px solid #0076ff;
      > button {
        display: block;
      }
    }
  `,
  ErrorMsg: styled.p`
    display: block;
    color: #fe0f00;
    font-size: 12px;
    font-weight: 400;
    margin-top: 8px;
  `,
  Input: styled(InputStyle.Input)<{ isError?: boolean }>`
    background: none;
    border: none;
    width: 220px;
    padding: 0;
    ${(p) => p.isError && 'color:#fe0f00;'}
    &:focus-within {
      border: transparent;
    }
  `,
  Label: styled.label`
    width: 40px;
    font-size: 14px;
    color: ${(p) => p.theme.map.moveCont.input.label};
    font-weight: 500;
    text-align: center;
  `,
  Remove: styled.button`
    display: none;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    &:before,
    &:after {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      content: '';
      width: 16px;
      height: 1px;
      background: #0076ff;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  `,
  Hr: styled.hr`
    border: 0;
    border-top: 1px solid ${(p) => p.theme.map.moveCont.hr};
    margin: 10px 0;
    display: block;
  `,
  BtnGroup: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  BtnMove: styled.button`
    border-radius: 2px;
    border: 1px solid ${(p) => p.theme.map.moveCont.btn.border};
    background: ${(p) => p.theme.map.moveCont.btn.bg};
    flex: 1;
    height: 40px;
    box-sizing: border-box;
    color: ${(p) => p.theme.map.moveCont.btn.color};
    font-size: 14px;
    font-weight: 400;
    transition: 0.4s;
    &:not(:last-child) {
      margin-right: 4px;
    }
    &:hover {
      color: ${(p) => p.theme.map.moveCont.btn.hoverColor};
      border-color: ${(p) => p.theme.map.moveCont.btn.hoverBorder};
      background: ${(p) => p.theme.map.moveCont.btn.hoverBg};
    }
  `,
  DesignLegend: styled.div`
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px;
    width: max-content;
    background: ${(p) => p.theme.map.moveCont.bg};
    border: 1px solid ${(p) => p.theme.map.moveCont.border};
    box-sizing: border-box;
    border-radius: 2px;
    transition: 0.4s;
    visibility: hidden;
    opacity: 0;
    &.active {
      right: calc(100% + 5px);
      visibility: visible;
      opacity: 1;
    }
  `,
  DesignRow: styled.div`
    display: flex;
    align-items: center;
  `,
};

function Toolbar({ onClick, goConstruct, style, is2DMap = false, point = undefined }: { onClick?: () => void; goConstruct?: () => void; style?: CSSProperties; is2DMap?: boolean; point?: ObjectMapPoint }) {
  //직각좌표계 검색 입력
  const [coordinates, setCoordinates] = useState({
    x: 0,
    y: 0,
  });
  const [toolStatus, setToolStatus] = useState({
    move: false,
    design: false,
  });
  const [isError, setIsError] = useState(false);

  //툴바에 컨텐츠가 있을때 on/off
  const onToolToggle = (str: string) => {
    setToolStatus({
      ...toolStatus,
      [str]: !toolStatus[str],
    });
  };

  //좌표검색 입력
  const onChangeFindCoordinates = (e, type) => {
    setIsError(false);
    if (type === 'x') {
      if (Number(e.target.value) > 6000000) {
        setCoordinates({
          ...coordinates,
          x: 6000000,
        });
      } else {
        setCoordinates({
          ...coordinates,
          x: Number(e.target.value),
        });
      }
    } else if (type === 'y') {
      if (Number(e.target.value) > 3000000) {
        setCoordinates({
          ...coordinates,
          y: 3000000,
        });
      } else {
        setCoordinates({
          ...coordinates,
          y: Number(e.target.value),
        });
      }
    }
  };
  //좌표이동 버튼 클릭
  const onClickMove = (coordinates) => {
    point?.findCoordinates(coordinates, setIsError);
  };

  //좌표이동 tool 꺼질때 레이어 숨김
  useEffect(() => {
    if (!toolStatus.move) {
      point?.hideXyLayer();
    }
  }, [toolStatus.move]);

  return (
    <ToolbarSt.Wrap style={style}>
      {is2DMap ? (
        <ToolbarSt.ToolWrap>
          <ToolbarSt.BtnWrap>
            <ToolbarSt.Button className={toolStatus.move ? 'active' : undefined} onClick={() => onToolToggle('move')}>
              <span className="ico move"></span>
              <span className="tooltip">{'좌표 검색'}</span>
            </ToolbarSt.Button>
            <ToolbarSt.Button onClick={onClick}>
              <span className="ico site"></span>
              <span className="tooltip">{'현장 위치'}</span>
            </ToolbarSt.Button>
            <ToolbarSt.Button onClick={goConstruct}>
              <span className="ico construct"></span>
              <span className="tooltip">{'마지막 시공 위치'}</span>
            </ToolbarSt.Button>
          </ToolbarSt.BtnWrap>

          {/* <ToolbarSt.Button className={toolStatus.move ? 'active' : undefined} onClick={() => onToolToggle('move')}>
            <span className="ico move"></span>
          </ToolbarSt.Button> */}

          {/* 좌표 검색창 */}
          <ToolbarSt.MoveCont className={toolStatus.move ? 'active' : undefined}>
            <ToolbarSt.MoveInner>
              <ToolbarSt.InputWrap style={{ marginBottom: 4 }} isError={isError}>
                <ToolbarSt.Label htmlFor={'moveX'}>X</ToolbarSt.Label>
                <ToolbarSt.Input
                  type={'number'}
                  id={'moveX'}
                  isError={isError}
                  placeholder={'입력하세요'}
                  min={'0'}
                  max={'6000000'}
                  value={String(coordinates.x) === '0' ? '' : String(coordinates.x)}
                  onChange={(e) => onChangeFindCoordinates(e, 'x')}
                />
                {coordinates.x ? (
                  <ToolbarSt.Remove type={'button'} onClick={() => setCoordinates({ ...coordinates, x: 0 })}>
                    <span className={'hidden'}>삭제</span>
                  </ToolbarSt.Remove>
                ) : null}
              </ToolbarSt.InputWrap>
              <ToolbarSt.InputWrap isError={isError}>
                <ToolbarSt.Label htmlFor={'moveY'}>Y</ToolbarSt.Label>
                <ToolbarSt.Input
                  type={'number'}
                  id={'moveY'}
                  placeholder={'입력하세요'}
                  isError={isError}
                  min={'0'}
                  max={'3000000'}
                  value={String(coordinates.y) === '0' ? '' : String(coordinates.y)}
                  onChange={(e) => onChangeFindCoordinates(e, 'y')}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      onClickMove(coordinates);
                    }
                  }}
                />
                {coordinates.y ? (
                  <ToolbarSt.Remove type={'button'} onClick={() => setCoordinates({ ...coordinates, y: 0 })}>
                    <span className={'hidden'}>삭제</span>
                  </ToolbarSt.Remove>
                ) : null}
              </ToolbarSt.InputWrap>
              {isError ? <ToolbarSt.ErrorMsg>정확한 좌표가 입력됐는지 확인해 주세요.</ToolbarSt.ErrorMsg> : null}
              <ToolbarSt.Hr />
              <ToolbarSt.BtnGroup>
                <ToolbarSt.BtnMove
                  type={'button'}
                  onClick={() => {
                    point?.hideXyLayer();
                  }}
                >
                  마커 삭제
                </ToolbarSt.BtnMove>
                <ToolbarSt.BtnMove
                  type={'button'}
                  onClick={() => {
                    onClickMove(coordinates);
                  }}
                >
                  좌표 이동
                </ToolbarSt.BtnMove>
              </ToolbarSt.BtnGroup>
            </ToolbarSt.MoveInner>
          </ToolbarSt.MoveCont>
        </ToolbarSt.ToolWrap>
      ) : null}
      {/* <ToolbarSt.Button onClick={onClick}>
        <span className="ico current"></span>
      </ToolbarSt.Button> */}
    </ToolbarSt.Wrap>
  );
}

export default Toolbar;
