import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import query from '../../hooks/RQuery/utils';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { themeState } from '../../store/Atoms';
import LabelInput, { LabelForm } from '../../components/atoms/LabelInput';
import ManageContent, { ManageContentMenuProps } from '../../components/templates/ManageContent';
import styled from 'styled-components';
import Input from '../../components/atoms/Input';

const MemberListStyle = {
  Wrap: styled.div`
    display: flex;
    flex-wrap: wrap;
  `,
  Card: styled.div`
    margin: 6px;
    border-radius: 12px;
    box-sizing: border-box;
    border: 1px solid #4a576b;
    background: #323d4e;
    width: 262px;
    height: 289px;
    padding-top: 44px;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Image: styled.div`
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-sizing: border-box;
    background: #273142;
    margin-bottom: 32px;
  `,
  Edit: styled.button`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    background: #29364e;
  `,
  Name: styled.strong`
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 13px;
  `,
  Role: styled.p`
    color: #a0aec0;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 9px;
    span {
      margin-left: 6px;
      &.admin {
        color: #f93c65;
      }
      &.manager {
        color: #4880ff;
      }
      &.user {
        color: #00b69b;
      }
    }
  `,
  Email: styled.p`
    font-weight: 500;
    font-size: 14px;
    color: #a0aec0;
  `,
};
//TODO 작업해야됨
const WorkPlaceDetail = () => {
  const [themeMode] = useRecoilState(themeState);
  const { t } = useTranslation();
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const codes = query.codes.useCodes();
  const { data: siteInfo } = query.sites.useSiteDetail({ siteId: sessionStorage.getItem('survey_siteId') });
  const [info, setInfo] = useState({
    address: '',
    contractor: [],
    facilityKinds: [],
    owner: [],
    projectName: '',
    projection: '',
    subContractor: [],
  });
  const menu: ManageContentMenuProps[] = [
    { key: 'info', pathName: `/workplace/detail/${id}/info`, text: '기본정보' },
    { key: 'owner', pathName: `/workplace/detail/${id}/owner`, text: '발주처정보' },
    { key: 'contractor', pathName: `/workplace/detail/${id}/contractor`, text: '시공사정보' },
    { key: 'subContractor', pathName: `/workplace/detail/${id}/subContractor`, text: '용역사정보' },
  ];
  useEffect(() => {
    if (siteInfo) setInfo(siteInfo);
  }, [siteInfo]);

  return (
    <ManageContent
      title={t('사업장상세정보')}
      menu={menu}
      listPath={'/workplace/list'}
      content={
        <>
          {location.pathname.includes('info') ? (
            <>
              <LabelInput label={t('주소')} id={'address'} value={info.address} width={400} style={{ marginBottom: 12 }} readOnly={true} />
              <LabelInput label={t('사업명')} id={'projectName'} value={info.projectName} width={400} style={{ marginBottom: 12 }} readOnly={true} />
              <LabelInput label={t('좌표계')} id={'projection'} value={info.projection} width={400} style={{ marginBottom: 12 }} readOnly={true} />
              <LabelForm.Wrap style={{ alignItems: 'flex-start' }}>
                <LabelForm.Label htmlFor={id} style={{ marginTop: 10 }}>
                  {t('관종별JobId')}
                </LabelForm.Label>
                <div>
                  {info.facilityKinds.map((facility: any, index) => {
                    return (
                      <LabelForm.Wrap style={{ marginBottom: 20 }} key={index}>
                        <LabelForm.Label>{t(codes.data.FACILITY.find(({ code }) => code === facility.facilityKind).value)}</LabelForm.Label>
                        <div>
                          {facility.jobId.map((i) => {
                            return <Input key={i} value={i} readOnly={true} />;
                          })}
                        </div>
                      </LabelForm.Wrap>
                    );
                  })}
                </div>
              </LabelForm.Wrap>
            </>
          ) : location.pathname.includes('owner') ? (
            info.owner.map((i: any, index) => {
              return (
                <div key={index}>
                  <LabelInput label={t('발주처명')} id={'companyName_' + index} value={i.companyName} width={400} style={{ marginBottom: 12 }} readOnly={true} />
                  <LabelInput label={t('담당자')} id={'companyManager_' + index} value={i.companyManager} width={400} style={{ marginBottom: 12 }} readOnly={true} />
                  <LabelInput label={t('이메일')} id={'email_' + index} value={i.email} width={400} style={{ marginBottom: 12 }} readOnly={true} />
                  <LabelInput label={t('사무실연락처')} id={'officeNumber_' + index} value={i.officeNumber} width={400} style={{ marginBottom: 12 }} readOnly={true} />
                  <LabelInput label={t('휴대폰번호')} id={'phoneNumber_' + index} value={i.phoneNumber} width={400} style={{ marginBottom: 12 }} readOnly={true} />
                </div>
              );
            })
          ) : location.pathname.includes('contractor') ? (
            info.contractor.map((i: any, index) => {
              return (
                <div key={index}>
                  <LabelInput label={t('시공사명')} id={'companyName_' + index} value={i.companyName} width={400} style={{ marginBottom: 12 }} readOnly={true} />
                  <LabelInput label={t('담당자')} id={'companyManager_' + index} value={i.companyManager} width={400} style={{ marginBottom: 12 }} readOnly={true} />
                  <LabelInput label={t('이메일')} id={'email_' + index} value={i.email} width={400} style={{ marginBottom: 12 }} readOnly={true} />
                  <LabelInput label={t('사무실연락처')} id={'officeNumber_' + index} value={i.officeNumber} width={400} style={{ marginBottom: 12 }} readOnly={true} />
                  <LabelInput label={t('휴대폰번호')} id={'phoneNumber_' + index} value={i.phoneNumber} width={400} style={{ marginBottom: 12 }} readOnly={true} />
                </div>
              );
            })
          ) : location.pathname.includes('subContractor') ? (
            info.subContractor.map((i: any, index) => {
              return (
                <div key={index}>
                  <LabelInput label={t('용역사명')} id={'companyName_' + index} value={i.companyName} width={400} style={{ marginBottom: 12 }} readOnly={true} />
                  <LabelInput label={t('담당자')} id={'companyManager_' + index} value={i.companyManager} width={400} style={{ marginBottom: 12 }} readOnly={true} />
                  <LabelInput label={t('이메일')} id={'email_' + index} value={i.email} width={400} style={{ marginBottom: 12 }} readOnly={true} />
                  <LabelInput label={t('사무실연락처')} id={'officeNumber_' + index} value={i.officeNumber} width={400} style={{ marginBottom: 12 }} readOnly={true} />
                  <LabelInput label={t('휴대폰번호')} id={'phoneNumber_' + index} value={i.phoneNumber} width={400} style={{ marginBottom: 12 }} readOnly={true} />
                </div>
              );
            })
          ) : null}
        </>
      }
    />
  );
};

export default WorkPlaceDetail;
