import * as wst from './wst';
import * as wtl from './wtl';
import * as rnw from './rnw';
import * as elt from './elt';
import * as gas from './gas';

/* 
   관 종류에 따라 [옵션] 리턴 
   모든 파이프 조회
 */
export function getPipesOptions({ siteId, facility }) {
  const queryOptions = {
    WATER: wtl.getPipesOptions({ siteId }),
    WASTE: wst.getPipesOptions({ siteId }),
    RAIN: rnw.getPipesOptions({ siteId }),
    GAS: gas.getPipesOptions({ siteId }),
    NETWORK: { queryKey: [], queryFn: null },
    ELECTRIC: elt.getPipesOptions({ siteId }),
    OIL: { queryKey: [], queryFn: null },
    HEAT: { queryKey: [], queryFn: null },
  };
  return queryOptions[facility];
}

/* 
   facility 조건을 걸어줘야 나머지 쿼리 요청하지 않음. 

   관 종류에 따라 [쿼리] 리턴 (1개씩)
*/
export function getPipesQuery({ siteId, facility }) {
  const facilityQuerys = {
    WATER: facility === 'WATER' && wtl.usePipes({ siteId }), // 상수 모든 파이프 조회
    WASTE: facility === 'WASTE' && wst.usePipes({ siteId }),
    RAIN: facility === 'RAIN' && rnw.usePipes({ siteId }),
    GAS: facility === 'GAS' && gas.usePipes({ siteId }),
    NETWORK: {},
    ELECTRIC: facility === 'ELECTRIC' && elt.usePipes({ siteId }),
    OIL: {},
    HEAT: {},
  };

  const fakeQuery = { data: [], isSuccess: false }; // 일치하는 쿼리가 없을때 리턴
  return facilityQuerys[facility] || fakeQuery;
}

// * 관 종류에 따른 측량성과등록 테이블 조회(측점) * //
export function getSurveyPipesQuery({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum, facility }) {
  const facilityQuerys = {
    WATER: facility === 'WATER' && wtl.useSurveyPipes({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }), // 상수, 측점 상세 조회
    WASTE: facility === 'WASTE' && wst.useSurveyPipes({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }), // 오수, 측점 상세 조회
    RAIN: facility === 'RAIN' && rnw.useSurveyPipes({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }), // 우수, 측점 상세 조회
    GAS: facility === 'GAS' && gas.useSurveyPipes({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }), // 가스, 측점 상세 조회,
    NETWORK: {},
    ELECTRIC: facility === 'ELECTRIC' && elt.useSurveyPipes({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }), // 전기, 측점 상세 조회,,
    OIL: {},
    HEAT: {},
  };

  const fakeQuery = { data: [], isSuccess: false }; // 일치하는 쿼리가 없을때 리턴
  return facilityQuerys[facility] || fakeQuery;
}

// 파이프 날짜별
export function getSurveyPipesByDateQuery({ siteId, date, facility, workDate }) {
  const facilityQuerys = {
    WATER: facility === 'WATER' && wtl.useSurveyPipesByDate({ siteId, date, workDate }), // 상수, 측점 - 날짜별 조회
    WASTE: facility === 'WASTE' && wst.useSurveyPipesByDate({ siteId, date, workDate }), // 오수, 측점 - 날짜별 조회
    RAIN: facility === 'RAIN' && rnw.useSurveyPipesByDate({ siteId, date, workDate }), // 우수, 측점 - 날짜별 조회
    GAS: facility === 'GAS' && gas.useSurveyPipesByDate({ siteId, date, workDate }), // 가스, 측점 - 날짜별 조회,
    NETWORK: {},
    ELECTRIC: facility === 'ELECTRIC' && elt.useSurveyPipesByDate({ siteId, date, workDate }), // 전기, 측점 - 날짜별 조회,,
    OIL: {},
    HEAT: {},
  };
  const fakeQuery = { data: [], isSuccess: false }; // 일치하는 쿼리가 없을때 리턴
  return facilityQuerys[facility] || fakeQuery;
}

//파이프 선택삭제
export function deleteSurveyPipes({ siteId, pipeIds, facility }) {
  const facilityMutates = {
    WATER: facility === 'WATER' && wtl.useSurveyDeletePipes({ pipeIds: pipeIds, siteId: siteId }),
    WASTE: facility === 'WASTE' && wst.useSurveyDeletePipes({ pipeIds: pipeIds, siteId: siteId }),
    RAIN: facility === 'RAIN' && rnw.useSurveyDeletePipes({ pipeIds: pipeIds, siteId: siteId }),
    GAS: facility === 'GAS' && gas.useSurveyDeletePipes({ pipeIds: pipeIds, siteId: siteId }),
    NETWORK: {},
    ELECTRIC: facility === 'ELECTRIC' && elt.useSurveyDeletePipes({ pipeIds: pipeIds, siteId: siteId }),
    OIL: {},
    HEAT: {},
  };
  return facilityMutates[facility];
}

//파이프 날짜삭제
export function deleteSurveyPipesByDate({ siteId, date, facility }) {
  const facilityMutates = {
    WATER: facility === 'WATER' && wtl.useSurveyDeletePipesByDate({ siteId: siteId, date: date }),
    WASTE: facility === 'WASTE' && wst.useSurveyDeletePipesByDate({ siteId: siteId, date: date }),
    RAIN: facility === 'RAIN' && rnw.useSurveyDeletePipesByDate({ siteId: siteId, date: date }),
    GAS: facility === 'GAS' && gas.useSurveyDeletePipesByDate({ siteId: siteId, date: date }),
    NETWORK: {},
    ELECTRIC: facility === 'ELECTRIC' && elt.useSurveyDeletePipesByDate({ siteId: siteId, date: date }),
    OIL: {},
    HEAT: {},
  };
  return facilityMutates[facility];
}

// export //
const pipes = {
  wtl,
  wst,
  rnw,
  elt,
  gas,
};

export default pipes;
