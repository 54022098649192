/* 
모든 React Query 쿼리 키 관리
*/

// 메인 쿼리 구분 (폴더 | 대분류 구분)
const MainKey = {
  AUTH: 'auth',
  CODE: 'code',
  CALENDAR: 'calendar',
  SITE: 'site',
  WORK_LOG: 'worklog',
  STATISTIC: 'statistic',
  FILE: 'file',
  PIPE: 'pipe',
  POINT: 'point',
  SURVEY: 'survey',
  DESIGN: 'design',
  SMARTPIN: 'smartpin',
  SHP: 'shp',
};

// [code]
// 참고 : 두번째 항목 apiUrl로 사용 `v1/codes/${type}`
export const codeKeys = {
  PIT: [MainKey.CODE, 'pit-cdes'],
  PIP: [MainKey.CODE, 'pip-cdes'],
  MOP: [MainKey.CODE, 'mop-cdes'],
  FACILITY: [MainKey.CODE, 'facility-kinds'],
  SBA: [MainKey.CODE, 'sba-cdes'],
  PVE: [MainKey.CODE, 'pve-cdes'],
  PFR: [MainKey.CODE, 'pfr-cdes'],
  FOR: [MainKey.CODE, 'for-cdes'],
  SOLUTION: [MainKey.CODE, 'solution'],
};

// [code] - tooltip
// 참고 : 두번째 항목 apiUrl로 사용 `v1/codes/${type}`
export const codeTooltipKeys = {
  PIPE: [MainKey.CODE, 'pipe-tooltips'],
  POINT: [MainKey.CODE, 'point-tooltips'],
};

// [site] - 현장, 사업장
export const siteKeys = {
  all: [MainKey.SITE] as const,
  list: (params) => [MainKey.SITE, 'list', { ...params }],
  detail: (id?: string) => [MainKey.SITE, 'detail', id], // CONSTRUCTION_INFO
};

// [worklog]
export const workLogKeys = {
  pipe: [MainKey.WORK_LOG, 'pipes'],
  point: [MainKey.WORK_LOG, 'points'],
  export: [MainKey.WORK_LOG, 'export'],
};

// [statistics]
export const statisticsKeys = {
  pipCdeDiameters: [MainKey.STATISTIC, 'pipCde-diameters'],
  dailyConstruction: [MainKey.STATISTIC, 'daily-construction'],
  mopCdeDiameters: [MainKey.STATISTIC, 'mopCde-diameters'],
  mopCdeDatesDiameters: [MainKey.STATISTIC, 'mopCde-dates-diameters'],
  processRate: [MainKey.STATISTIC, 'process-rate'],
  facilityKindProcessRate: [MainKey.STATISTIC, 'facilityKind-process-rate'],
};

// [calendar]
export const calendarKeys = {
  all: (siteId) => [MainKey.CALENDAR, siteId],
  surveyPoints: (params) => [MainKey.CALENDAR, 'surveyPoints', { ...params }],
  surveyPipes: (params) => [MainKey.CALENDAR, 'surveyPipes', { ...params }],
};

// [files]
export const fileKeys = {
  image: [MainKey.FILE, 'images'],
  facilities: [MainKey.FILE, 'facilities'],
  report: [MainKey.FILE, 'report'],
};

// [point]
export const pointKeys = {
  wtl: {
    common: ['wtl-points'],
    all: (siteId) => ['wtl-points', siteId],
    jointDetail: (pointId: string) => ['wtl-joints', pointId],
    holeDetail: (pointId: string) => ['wtl-holes', pointId],
    valveDetail: (pointId: string) => ['wtl-valves', pointId],
    fireDetail: (pointId: string) => ['wtl-fires', pointId],

    survey: {
      all: (params) => [MainKey.SURVEY, 'wtl-points', { ...params }],
      date: (params) => ['date', MainKey.SURVEY, 'wtl-points', { ...params }],
      bbox: ['bbox', MainKey.SURVEY, 'wtl-points'],

      joints: (params) => [MainKey.SURVEY, 'wtl-joints', { ...params }],
      holes: (params) => [MainKey.SURVEY, 'wtl-holes', { ...params }],
      valves: (params) => [MainKey.SURVEY, 'wtl-valves', { ...params }],
      fires: (params) => [MainKey.SURVEY, 'fires', { ...params }],
    },
  },
  wst: {
    common: ['wst-points'],
    all: (siteId) => ['wst-points', siteId],
    jointDetail: (pointId: string) => ['wst-joints', pointId],
    holeDetail: (pointId: string) => ['wst-holes', pointId],
    valveDetail: (pointId: string) => ['wst-valves', pointId],

    survey: {
      all: (params) => [MainKey.SURVEY, 'wst-points', { ...params }],
      date: (params) => ['date', MainKey.SURVEY, 'wst-points', { ...params }],
      bbox: ['bbox', MainKey.SURVEY, 'wst-points'],

      joints: (params) => [MainKey.SURVEY, 'wst-joints', { ...params }],
      holes: (params) => [MainKey.SURVEY, 'wst-holes', { ...params }],
      valves: (params) => [MainKey.SURVEY, 'wst-valves', { ...params }],
    },
  },
  rnw: {
    common: ['rnw-points'],
    all: (siteId) => ['rnw-points', siteId],
    jointDetail: (pointId: string) => ['rnw-joints', pointId],
    holeDetail: (pointId: string) => ['rnw-holes', pointId],
    valveDetail: (pointId: string) => ['rnw-valves', pointId],

    survey: {
      all: (params) => [MainKey.SURVEY, 'rnw-points', { ...params }],
      date: (params) => ['date', MainKey.SURVEY, 'rnw-points', { ...params }],
      bbox: ['bbox', MainKey.SURVEY, 'rnw-points'],

      joints: (params) => [MainKey.SURVEY, 'rnw-joints', { ...params }],
      holes: (params) => [MainKey.SURVEY, 'rnw-holes', { ...params }],
      valves: (params) => [MainKey.SURVEY, 'rnw-valves', { ...params }],
    },
  },
  elt: {
    common: ['elt-points'],
    all: (siteId) => ['elt-points', siteId],
    jointDetail: (pointId: string) => ['elt-joints', pointId],
    holeDetail: (pointId: string) => ['elt-holes', pointId],
    valveDetail: (pointId: string) => ['elt-valves', pointId],

    survey: {
      all: (params) => [MainKey.SURVEY, 'elt-points', { ...params }],
      date: (params) => ['date', MainKey.SURVEY, 'elt-points', { ...params }],
      bbox: ['bbox', MainKey.SURVEY, 'elt-points'],

      joints: (params) => [MainKey.SURVEY, 'elt-joints', { ...params }],
      holes: (params) => [MainKey.SURVEY, 'elt-holes', { ...params }],
      valves: (params) => [MainKey.SURVEY, 'elt-valves', { ...params }],
    },
  },
  gas: {
    common: ['gas-points'],
    all: (siteId) => ['gas-points', siteId],
    jointDetail: (pointId: string) => ['gas-joints', pointId],
    holeDetail: (pointId: string) => ['gas-holes', pointId],
    valveDetail: (pointId: string) => ['gas-valves', pointId],

    survey: {
      all: (params) => [MainKey.SURVEY, 'gas-points', { ...params }],
      date: (params) => ['date', MainKey.SURVEY, 'gas-points', { ...params }],
      bbox: ['bbox', MainKey.SURVEY, 'gas-points'],

      joints: (params) => [MainKey.SURVEY, 'gas-joints', { ...params }],
      holes: (params) => [MainKey.SURVEY, 'gas-holes', { ...params }],
      valves: (params) => [MainKey.SURVEY, 'gas-valves', { ...params }],
    },
  },
};

// [pipe]
export const pipeKeys = {
  wtl: {
    common: ['wtl-pipes'],
    all: (siteId) => ['wtl-pipes', siteId],
    survey: {
      all: (params) => [MainKey.SURVEY, 'wtl-pipes', { ...params }],
      date: (params) => ['date', MainKey.SURVEY, 'wtl-pipes', { ...params }],
    },
  },
  wst: {
    common: ['wst-pipes'],
    all: (siteId) => ['wst-pipes', siteId],
    survey: {
      all: (params) => [MainKey.SURVEY, 'wst-pipes', { ...params }],
      date: (params) => ['date', MainKey.SURVEY, 'wst-pipes', { ...params }],
    },
  },
  rnw: {
    common: ['rnw-pipes'],
    all: (siteId) => ['rnw-pipes', siteId],
    survey: {
      all: (params) => [MainKey.SURVEY, 'rnw-pipes', { ...params }],
      date: (params) => ['date', MainKey.SURVEY, 'rnw-pipes', { ...params }],
    },
  },
  elt: {
    common: ['elt-pipes'],
    all: (siteId) => ['elt-pipes', siteId],
    survey: {
      all: (params) => [MainKey.SURVEY, 'elt-pipes', { ...params }],
      date: (params) => ['date', MainKey.SURVEY, 'elt-pipes', { ...params }],
    },
  },
  gas: {
    common: ['gas-pipes'],
    all: (siteId) => ['gas-pipes', siteId],
    survey: {
      all: (params) => [MainKey.SURVEY, 'gas-pipes', { ...params }],
      date: (params) => ['date', MainKey.SURVEY, 'gas-pipes', { ...params }],
    },
  },
};

// [auth]
export const authKeys = {
  all: [MainKey.AUTH] as const,
  companies: {
    all: (params) => [MainKey.AUTH, 'companies', { ...params }],
    detail: (companyId) => [MainKey.AUTH, 'companies', companyId],
  },
  duplicate: ['duplicate'],
  memberList: (params) => [MainKey.AUTH, 'memberList', { ...params }],
};

// [design]
export const designKeys = {
  all: (params) => [MainKey.DESIGN, { ...params }],
};

export const smartpinKeys = {
  all: [MainKey.SMARTPIN],
  pipe: (params) => [MainKey.SMARTPIN, 'pipe', { ...params }],
  hole: (params) => [MainKey.SMARTPIN, 'hole', { ...params }],
  pin: (params) => [MainKey.SMARTPIN, 'pin', { ...params }],
};

export const shpKeys = {
  list: (siteId) => [MainKey.SHP, siteId],
};

// 필수 !! invalidate 필요
const queryKeys = {
  MainKey,
  codeKeys,
  siteKeys,
  pointKeys,
  pipeKeys,
  designKeys,
  smartpinKeys,
};
export default queryKeys;
