import React, { useEffect, useState } from 'react';
import PagingTable from '../../atoms/PagingTable';
import { FilterProps } from '../_interface';
import Checkbox from '../../atoms/Checkbox';
import Button from '../../atoms/Button';
import SurveyUpload from './SurveyUpload';
import { cellStatus, FILTERLIST } from '../../../util/global';
import { request } from '../../../service/api/common';
import { SurveyStyle } from '../_style';
import Select from '../../atoms/Select';
import NoData from '../../atoms/NoData';
import { useTranslation } from 'react-i18next';
import SurveyImageTemp from './SurveyImageTemp';
import { useSetRecoilState } from 'recoil';
import { tableListPoint } from 'store/surveyAtoms';
import query from 'hooks/RQuery/utils';

interface CheckBoxProps {
  id: string;
  checked: boolean;
}

const SurveyPointTable = ({ onColumnToggle, activeCategory, activeTab, date, searchTrigger, ptNum, onPopupToggle, siteInfo, firstCall, setFirstCall }) => {
  const siteId = siteInfo.id;
  const { t } = useTranslation();
  const setTableListPoint = useSetRecoilState(tableListPoint);

  const [totalPages, setTotalPages] = useState<any>(0);
  //colGroup 배열
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState([
    { text: '10', value: 10, active: true },
    { text: '15', value: 15, active: false },
    { text: '20', value: 20, active: false },
  ]);
  const [tableData, setTableData] = useState<any>([]);
  const [sort, setSort] = useState('DESC');
  const [isNoData, setIsNoData] = useState(true);

  //포인트 컬럼리스트
  const [pointFilter, setPointFilter] = useState<FilterProps[]>(FILTERLIST.POINTS);

  //연결부 컬럼리스트
  const [pipePointFilter, setPipePointFilter] = useState<FilterProps[]>(FILTERLIST.PIPE_POINTS);

  //맨홀 컬럼리스트
  const [holeFilter, setHoleFilter] = useState<FilterProps[]>(FILTERLIST.HOLES);

  //밸브 컬럼리스트
  const [valveFilter, setValveFilter] = useState<FilterProps[]>(FILTERLIST.VALVES);

  //소화전 컬럼리스트
  const [fireFilter, setFireFilter] = useState<FilterProps[]>(FILTERLIST.FIRES);

  //체크박스 배열
  const [checkList, setCheckList] = useState<CheckBoxProps[]>([]);

  const [checkAll, setCheckAll] = useState(false);

  //공통코드
  const code = query.codes.useCodes();

  let params = { siteId, pageSize: pageSize.filter((i) => i.active)[0].value, pageNum: currentPage, sort, startDate: date.from?.format('YYYY-MM-DD'), endDate: date.to?.format('YYYY-MM-DD'), ptNum };
  //전체측점 리스트 (탭 별로)
  const allPointsList = query.survey.points.useSurveyPoints({ info: { type: activeTab, ...params, facility: activeCategory } });
  // 체크리스트 갱신마다 recoil 저장
  useEffect(() => {
    if (checkList.length > 0) {
      const newObj: any = {
        points: [],
        pipes: [],
      };
      const checkedRowList = checkList.filter(({ checked }) => checked);
      checkedRowList.map((i) => {
        newObj.points.push({
          ptNum: tableData.filter(({ pointId }) => pointId === i.id)[0].ptNum,
          pointId: tableData.filter(({ pointId }) => pointId === i.id)[0].pointId,
        });
      });
      setTableListPoint({
        checked: [...checkList.filter((ele) => ele.checked)?.map((ele) => ele?.id)],
        unChecked: [...checkList.filter((ele) => !ele.checked)?.map((ele) => ele?.id)],
        checkedPointData: newObj,
      });
    }
  }, [checkList]);

  //[성공시]테이블 데이터 호출
  useEffect(() => {
    if (allPointsList?.data) {
      const newCheckList: CheckBoxProps[] = [];
      const orgDataList = allPointsList?.data?.list || []; // 오리지널 데이터
      let activeData: any = []; // active 탭에 맞는 데이터

      if (activeTab === 'points') {
        activeData = orgDataList;
        setTableData(activeData); // 테이블 리스트 저장
      }
      if (activeTab === 'joints') {
        activeData = orgDataList.filter(({ pitCde }) => pitCde !== 'PIT006' && pitCde !== 'PIT007' && pitCde !== 'PIT008') || [];
        setTableData(activeData);
      }
      if (activeTab === 'holes') {
        activeData = orgDataList.filter(({ pitCde }) => pitCde === 'PIT007') || [];
        setTableData(activeData);
      }
      if (activeTab === 'valves') {
        activeData = orgDataList.filter(({ pitCde }) => pitCde === 'PIT006') || [];
        setTableData(activeData);
      }
      if (activeTab === 'fires') {
        activeData = orgDataList.filter(({ pitCde }) => pitCde === 'PIT008') || [];
        setTableData(activeData);
      }

      // 체크 리스트 저장
      activeData.map((i) => {
        newCheckList.push({ id: i.pointId, checked: false });
      });
      setCheckList(newCheckList);

      setTableData(activeData);
      setTotalPages(allPointsList?.data?.page?.totalPages);
    }
  }, [allPointsList?.data]);

  // 에러처리
  useEffect(() => {
    if (allPointsList?.error) {
      setTableData([]);
    }
  }, [allPointsList?.error]);

  //체크박스 클릭시 체크리스트 갱신
  const onCheck = (id) => {
    const newCheckList = [...checkList];
    newCheckList.filter((i) => i.id === id)[0].checked = !newCheckList.filter((i) => i.id === id)[0].checked;
    setCheckList(newCheckList);
  };

  //th 체크박스 체크이벤트
  const onCheckAll = () => {
    const newCheckList = [...checkList];
    //테이블 length와 체크된 length가 같을 때 전체 해제
    if (newCheckList.length === newCheckList.filter((i) => i.checked).length) {
      newCheckList.map((i) => {
        i.checked = false;
      });
      setCheckList(newCheckList);

      //테이블 length와 체크된 length가 다를 때 전체 선택
    } else {
      newCheckList.map((i) => {
        i.checked = true;
      });
      setCheckList(newCheckList);
    }
  };

  //최초 전체측점 데이터 있을 시 테이블 표시, 없을 시 업로드화면 표시
  useEffect(() => {
    if (firstCall) {
      setIsNoData(true);
    } else {
      setIsNoData(false);
    }
  }, [firstCall]);

  //select에서 pageSize변경시 액션
  useEffect(() => {
    //현재 페이지가 1이 아닐땐 1페이지로 이동
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  }, [pageSize, activeTab, sort, searchTrigger]);

  useEffect(() => {
    allPointsList.refetch();
  }, [searchTrigger]);

  //페이지 내 체크박스 전부 체크됐을때 th 체크박스 체크
  useEffect(() => {
    if (checkList.length) {
      checkList.length === checkList.filter((i) => i.checked).length ? setCheckAll(true) : setCheckAll(false);
    }
  }, [checkList.filter((i) => i.checked).length]);
  return (
    <>
      {isNoData ? (
        <SurveyUpload siteInfo={siteInfo} setFirstCall={setFirstCall} />
      ) : (
        <>
          <SurveyStyle.FilterArea>
            <SurveyStyle.FilterWrap>
              {
                /* 전체 측점 포인트정보 보이기/숨기기 버튼들 */
                activeTab === 'points'
                  ? pointFilter.map((i, index) => {
                      return (
                        <SurveyStyle.FilterItem key={index} className={i.active ? 'active' : undefined} onClick={() => onColumnToggle(pointFilter, setPointFilter, i.key)}>
                          {t(i.name)}
                        </SurveyStyle.FilterItem>
                      );
                    })
                  : activeTab === 'joints'
                    ? pipePointFilter.map((i, index) => {
                        return (
                          <SurveyStyle.FilterItem key={index} className={i.active ? 'active' : undefined} onClick={() => onColumnToggle(pipePointFilter, setPipePointFilter, i.key)}>
                            {t(i.name)}
                          </SurveyStyle.FilterItem>
                        );
                      })
                    : activeTab === 'holes'
                      ? holeFilter.map((i, index) => {
                          return (
                            <SurveyStyle.FilterItem key={index} className={i.active ? 'active' : undefined} onClick={() => onColumnToggle(holeFilter, setHoleFilter, i.key)}>
                              {t(i.name)}
                            </SurveyStyle.FilterItem>
                          );
                        })
                      : activeTab === 'valves'
                        ? valveFilter.map((i, index) => {
                            return (
                              <SurveyStyle.FilterItem key={index} className={i.active ? 'active' : undefined} onClick={() => onColumnToggle(valveFilter, setValveFilter, i.key)}>
                                {t(i.name)}
                              </SurveyStyle.FilterItem>
                            );
                          })
                        : activeTab === 'fires'
                          ? fireFilter.map((i, index) => {
                              return (
                                <SurveyStyle.FilterItem key={index} className={i.active ? 'active' : undefined} onClick={() => onColumnToggle(fireFilter, setFireFilter, i.key)}>
                                  {t(i.name)}
                                </SurveyStyle.FilterItem>
                              );
                            })
                          : null
              }
            </SurveyStyle.FilterWrap>
            <Select
              option={pageSize}
              width={76}
              label={t('표시갯수')}
              onChange={(e) => {
                const newArr = [...pageSize];
                newArr.map((i) => {
                  i.active = Number((e.target as HTMLLIElement).ariaValueText) === i.value;
                });
                setPageSize(newArr);
              }}
            />
          </SurveyStyle.FilterArea>

          <div style={{ padding: '0 24px', width: 'calc(100% - 48px)', height: 'calc(100% - 102px)', overflow: 'auto' }}>
            {tableData?.length ? (
              <PagingTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                width={activeTab === 'points' ? undefined : 1800 - pointFilter.filter(({ active }) => !active).length * 120}
                caption={'측량정보 테이블'}
                height={'calc(100% - 100px)'}
                thead={
                  //전체측점
                  activeTab === 'points' ? (
                    <tr>
                      <th scope={'col'} style={{ width: 70, position: 'sticky', left: 0, zIndex: 2 }}>
                        <Checkbox dark={true} size={18} id={'checkAll'} checked={checkAll} onChange={onCheckAll} />
                      </th>
                      <th scope={'col'} style={{ width: 150, position: 'sticky', left: 70, zIndex: 2 }}>
                        <button
                          type={'button'}
                          className={sort === 'ASC' ? 'asc' : 'desc'}
                          onClick={() => {
                            if (sort === 'DESC') {
                              setSort('ASC');
                            } else {
                              setSort('DESC');
                            }
                          }}
                        >
                          {t('측점명')}
                        </button>
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(pointFilter, 'x') }}>
                        X
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(pointFilter, 'y') }}>
                        Y
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(pointFilter, 'z') }}>
                        Z
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(pointFilter, 'pit') }}>
                        {t('유형')}
                      </th>
                      <th scope={'col'}>{t('측량일')}</th>
                    </tr>
                  ) : // 연결부
                  activeTab === 'joints' ? (
                    <tr>
                      <th scope={'col'} style={{ width: 70, position: 'sticky', left: 0, zIndex: 2 }}>
                        <Checkbox dark={true} size={18} id={'checkAll'} checked={checkAll} onChange={onCheckAll} />
                      </th>
                      <th scope={'col'} style={{ width: 150, position: 'sticky', left: 70, zIndex: 2 }}>
                        <button
                          type={'button'}
                          className={sort === 'ASC' ? 'asc' : 'desc'}
                          onClick={() => {
                            if (sort === 'DESC') {
                              setSort('ASC');
                            } else {
                              setSort('DESC');
                            }
                          }}
                        >
                          {t('측점명')}
                        </button>
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(pipePointFilter, 'x') }}>
                        X
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(pipePointFilter, 'y') }}>
                        Y
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(pipePointFilter, 'z') }}>
                        Z
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(pipePointFilter, 'depth') }}>
                        {t('심도')}
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(pipePointFilter, 'el') }}>
                        {t('지반고')}
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(pipePointFilter, 'angle') }}>
                        {t('각도')}
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(pipePointFilter, 'pit') }}>
                        {t('유형')}
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(pipePointFilter, 'near') }}>
                        {t('근경')}
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(pipePointFilter, 'distant') }}>
                        {t('원경')}
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(pipePointFilter, 'elNear') }}>
                        {t('지반고근경')}
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(pipePointFilter, 'elDistant') }}>
                        {t('지반고원경')}
                      </th>
                      <th scope={'col'}>{t('측량일')}</th>
                    </tr>
                  ) : activeTab === 'holes' ? (
                    <tr>
                      <th scope={'col'} style={{ width: 70, position: 'sticky', left: 0, zIndex: 2 }}>
                        <Checkbox dark={true} size={18} id={'checkAll'} checked={checkAll} onChange={onCheckAll} />
                      </th>
                      <th scope={'col'} style={{ width: 150, position: 'sticky', left: 70, zIndex: 2 }}>
                        <button
                          type={'button'}
                          className={sort === 'ASC' ? 'asc' : 'desc'}
                          onClick={() => {
                            if (sort === 'DESC') {
                              setSort('ASC');
                            } else {
                              setSort('DESC');
                            }
                          }}
                        >
                          {t('측점명')}
                        </button>
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(holeFilter, 'x') }}>
                        X
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(holeFilter, 'y') }}>
                        Y
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(holeFilter, 'z') }}>
                        Z
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(holeFilter, 'high') }}>
                        {t('높이')}
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(holeFilter, 'angle') }}>
                        {t('각도')}
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(holeFilter, 'diameter') }}>
                        {t('구경')}
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(holeFilter, 'mop') }}>
                        {t('재질')}
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(holeFilter, 'sba') }}>
                        {t('종류')}
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(holeFilter, 'for') }}>
                        {t('형태')}
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(holeFilter, 'hol') }}>
                        {t('가로')}
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(holeFilter, 'vel') }}>
                        {t('세로')}
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(holeFilter, 'insideImg') }}>
                        {t('내경이미지')}
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(holeFilter, 'outsideImg') }}>
                        {t('전경이미지')}
                      </th>
                      <th scope={'col'}>{t('측량일')}</th>
                    </tr>
                  ) : activeTab === 'valves' ? (
                    <tr>
                      <th scope={'col'} style={{ width: 70, position: 'sticky', left: 0, zIndex: 2 }}>
                        <Checkbox dark={true} size={18} id={'checkAll'} checked={checkAll} onChange={onCheckAll} />
                      </th>
                      <th scope={'col'} style={{ width: 150, position: 'sticky', left: 70, zIndex: 2 }}>
                        <button
                          type={'button'}
                          className={sort === 'ASC' ? 'asc' : 'desc'}
                          onClick={() => {
                            if (sort === 'DESC') {
                              setSort('ASC');
                            } else {
                              setSort('DESC');
                            }
                          }}
                        >
                          {t('측점명')}
                        </button>
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(valveFilter, 'x') }}>
                        X
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(valveFilter, 'y') }}>
                        Y
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(valveFilter, 'z') }}>
                        Z
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(valveFilter, 'depth') }}>
                        {t('심도')}
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(valveFilter, 'angle') }}>
                        {t('각도')}
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(valveFilter, 'diameter') }}>
                        {t('관경')}
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(valveFilter, 'mop') }}>
                        {t('재질')}
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(valveFilter, 'pve') }}>
                        {t('종류')}
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(valveFilter, 'near') }}>
                        {t('근경이미지')}
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(valveFilter, 'distant') }}>
                        {t('원경이미지')}
                      </th>
                      <th scope={'col'}>{t('측량일')}</th>
                    </tr>
                  ) : activeTab === 'fires' ? (
                    <tr>
                      <th scope={'col'} style={{ width: 70, position: 'sticky', left: 0, zIndex: 2 }}>
                        <Checkbox dark={true} size={18} id={'checkAll'} checked={checkAll} onChange={onCheckAll} />
                      </th>
                      <th scope={'col'} style={{ width: 150, position: 'sticky', left: 70, zIndex: 2 }}>
                        <button
                          type={'button'}
                          className={sort === 'ASC' ? 'asc' : 'desc'}
                          onClick={() => {
                            if (sort === 'DESC') {
                              setSort('ASC');
                            } else {
                              setSort('DESC');
                            }
                          }}
                        >
                          {t('측점명')}
                        </button>
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(fireFilter, 'x') }}>
                        X
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(fireFilter, 'y') }}>
                        Y
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(fireFilter, 'z') }}>
                        Z
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(fireFilter, 'angle') }}>
                        {t('각도')}
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(fireFilter, 'diameter') }}>
                        {t('관경')}
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(fireFilter, 'pfr') }}>
                        {t('종류')}
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(fireFilter, 'near') }}>
                        {t('근경이미지')}
                      </th>
                      <th scope={'col'} style={{ display: cellStatus(fireFilter, 'distant') }}>
                        {t('원경이미지')}
                      </th>
                      <th scope={'col'}>{t('측량일')}</th>
                    </tr>
                  ) : null
                }
                tbody={
                  <>
                    {tableData?.map((i) => {
                      if (activeTab === 'points') {
                        //전체측점
                        return (
                          <tr key={i.pointId} onClick={() => onCheck(i.pointId)}>
                            <td style={{ width: 70, position: 'sticky', left: 0 }}>
                              <Checkbox dark={true} size={18} id={i.pointId} checked={checkList.filter(({ id }) => id === i.pointId)[0].checked} onChange={() => onCheck(i.pointId)} />
                            </td>
                            <td style={{ width: 150, position: 'sticky', left: 70 }}>{i.ptNum}</td>
                            <td style={{ display: cellStatus(pointFilter, 'x') }}>{Number(i.x)?.toFixed(3) || '-'}</td>
                            <td style={{ display: cellStatus(pointFilter, 'y') }}>{Number(i.y)?.toFixed(3) || '-'}</td>
                            <td style={{ display: cellStatus(pointFilter, 'z') }}>{Number(i.z)?.toFixed(3) || '-'}</td>
                            <td style={{ display: cellStatus(pointFilter, 'pit') }}>{t(code.data?.PIT.filter(({ code }) => code === i.pitCde)[0].value)}</td>
                            <td>{i.ymd}</td>
                          </tr>
                        );
                      } else if (activeTab === 'joints') {
                        //연결부
                        return (
                          <tr key={i.pointId} onClick={() => onCheck(i.pointId)}>
                            <td style={{ width: 70, position: 'sticky', left: 0, zIndex: 20 }}>
                              <Checkbox dark={true} size={18} id={i.pointId} checked={checkList.filter(({ id }) => id === i.pointId)[0].checked} onChange={() => onCheck(i.pointId)} />
                            </td>
                            <td style={{ width: 150, position: 'sticky', left: 70, zIndex: 20 }}>{i.ptNum}</td>
                            <td style={{ display: cellStatus(pipePointFilter, 'x') }}>{Number(i.x)?.toFixed(3) || '-'}</td>
                            <td style={{ display: cellStatus(pipePointFilter, 'y') }}>{Number(i.y)?.toFixed(3) || '-'}</td>
                            <td style={{ display: cellStatus(pipePointFilter, 'z') }}>{Number(i.z)?.toFixed(3) || '-'}</td>
                            <td style={{ display: cellStatus(pipePointFilter, 'depth') }}>{Number(i.depth)?.toFixed(3) || '-'}</td>
                            <td style={{ display: cellStatus(pipePointFilter, 'el') }}>{Number(i.el)?.toFixed(3) || '-'}</td>
                            <td style={{ display: cellStatus(pipePointFilter, 'angle') }}>{i.angle || '-'}</td>
                            <td style={{ display: cellStatus(pipePointFilter, 'pit') }}>{t(code.data?.PIT.filter(({ code }) => code === i.pitCde)[0].value)}</td>
                            <td style={{ display: cellStatus(pipePointFilter, 'near') }}>
                              <SurveyImageTemp
                                activeCategory={activeCategory}
                                siteInfo={siteInfo}
                                src={i.images?.filter((i) => i.imageType === 'NEAR_IMAGE')[0]?.imageThumbUrl || ''}
                                ptNum={i.ptNum}
                                id={i.pointId}
                                typeNum={1}
                                refetchApi={allPointsList}
                                item={i}
                              />
                            </td>
                            <td style={{ display: cellStatus(pipePointFilter, 'distant') }}>
                              <SurveyImageTemp
                                activeCategory={activeCategory}
                                siteInfo={siteInfo}
                                src={i.images?.filter((i) => i.imageType === 'DISTANT_IMAGE')[0]?.imageThumbUrl || ''}
                                ptNum={i.ptNum}
                                id={i.pointId}
                                typeNum={2}
                                refetchApi={allPointsList}
                                item={i}
                              />
                            </td>
                            <td style={{ display: cellStatus(pipePointFilter, 'elNear') }}>
                              <SurveyImageTemp
                                activeCategory={activeCategory}
                                siteInfo={siteInfo}
                                src={i.images?.filter((i) => i.imageType === 'EL_NEAR_IMAGE')[0]?.imageThumbUrl || ''}
                                ptNum={i.ptNum}
                                id={i.pointId}
                                typeNum={3}
                                refetchApi={allPointsList}
                                item={i}
                              />
                            </td>
                            <td style={{ display: cellStatus(pipePointFilter, 'elDistant') }}>
                              <SurveyImageTemp
                                activeCategory={activeCategory}
                                siteInfo={siteInfo}
                                src={i.images?.filter((i) => i.imageType === 'EL_DISTANT_IMAGE')[0]?.imageThumbUrl || ''}
                                ptNum={i.ptNum}
                                id={i.pointId}
                                typeNum={4}
                                refetchApi={allPointsList}
                                item={i}
                              />
                            </td>
                            <td>{i.ymd}</td>
                          </tr>
                        );
                      } else if (activeTab === 'holes') {
                        //맨홀
                        return (
                          <tr key={i.pointId} onClick={() => onCheck(i.pointId)}>
                            <td style={{ width: 70, position: 'sticky', left: 0, zIndex: 20 }}>
                              <Checkbox dark={true} size={18} id={i.pointId} checked={checkList.filter(({ id }) => id === i.pointId)[0].checked} onChange={() => onCheck(i.pointId)} />
                            </td>
                            <td style={{ width: 150, position: 'sticky', left: 70, zIndex: 20 }}>{i.ptNum}</td>
                            <td style={{ display: cellStatus(holeFilter, 'x') }}>{Number(i.x)?.toFixed(3) || '-'}</td>
                            <td style={{ display: cellStatus(holeFilter, 'y') }}>{Number(i.y)?.toFixed(3) || '-'}</td>
                            <td style={{ display: cellStatus(holeFilter, 'z') }}>{Number(i.z)?.toFixed(3) || '-'}</td>
                            <td style={{ display: cellStatus(holeFilter, 'high') }}>{Number(i.high)?.toFixed(3) || '-'}</td>
                            <td style={{ display: cellStatus(holeFilter, 'angle') }}>{i.angle || '-'}</td>
                            <td style={{ display: cellStatus(holeFilter, 'diameter') }}>{Number(i.diameter) || '-'}</td>
                            <td style={{ display: cellStatus(holeFilter, 'mop') }}>{t(code.data?.MOP.filter(({ code }) => code === i.mopCde)[0]?.value) || '-'}</td>
                            <td style={{ display: cellStatus(holeFilter, 'sba') }}>{t(code.data?.SBA.filter(({ code }) => code === i.sbaCde)[0]?.value) || '-'}</td>
                            <td style={{ display: cellStatus(holeFilter, 'for') }}>{t(code.data?.FOR.filter(({ code }) => code === i.forCde)[0]?.value) || '-'}</td>
                            <td style={{ display: cellStatus(holeFilter, 'hol') }}>{Number(i.hol)?.toFixed(3) || '-'}</td>
                            <td style={{ display: cellStatus(holeFilter, 'vel') }}>{Number(i.vel)?.toFixed(3) || '-'}</td>
                            <td style={{ display: cellStatus(holeFilter, 'insideImg') }}>
                              <SurveyImageTemp
                                activeCategory={activeCategory}
                                siteInfo={siteInfo}
                                src={i.images?.filter((i) => i.imageType === 'NEAR_IMAGE')[0]?.imageThumbUrl || ''}
                                ptNum={i.ptNum}
                                id={i.pointId}
                                typeNum={1}
                                refetchApi={allPointsList}
                                item={i}
                              />
                            </td>
                            <td style={{ display: cellStatus(holeFilter, 'outsideImg') }}>
                              <SurveyImageTemp
                                activeCategory={activeCategory}
                                siteInfo={siteInfo}
                                src={i.images?.filter((i) => i.imageType === 'DISTANT_IMAGE')[0]?.imageThumbUrl || ''}
                                ptNum={i.ptNum}
                                id={i.pointId}
                                typeNum={2}
                                refetchApi={allPointsList}
                                item={i}
                              />
                            </td>
                            <td>{i.ymd}</td>
                          </tr>
                        );
                      } else if (activeTab === 'valves') {
                        //밸브
                        return (
                          <tr key={i.pointId} onClick={() => onCheck(i.pointId)}>
                            <td style={{ width: 70, position: 'sticky', left: 0, zIndex: 20 }}>
                              <Checkbox dark={true} size={18} id={i.pointId} checked={checkList.filter(({ id }) => id === i.pointId)[0].checked} onChange={() => onCheck(i.pointId)} />
                            </td>
                            <td style={{ width: 150, position: 'sticky', left: 70, zIndex: 20 }}>{i.ptNum}</td>
                            <td style={{ display: cellStatus(valveFilter, 'x') }}>{Number(i.x)?.toFixed(3) || '-'}</td>
                            <td style={{ display: cellStatus(valveFilter, 'y') }}>{Number(i.y)?.toFixed(3) || '-'}</td>
                            <td style={{ display: cellStatus(valveFilter, 'z') }}>{Number(i.z)?.toFixed(3) || '-'}</td>
                            <td style={{ display: cellStatus(valveFilter, 'depth') }}>{Number(i.depth)?.toFixed(3) || '-'}</td>
                            <td style={{ display: cellStatus(valveFilter, 'angle') }}>{i.angle || '-'}</td>
                            <td style={{ display: cellStatus(valveFilter, 'diameter') }}>{i.diameter || '-'}</td>
                            <td style={{ display: cellStatus(valveFilter, 'mop') }}>{t(code.data?.MOP.filter(({ code }) => code === i.mopCde)[0]?.value) || '-'}</td>
                            <td style={{ display: cellStatus(valveFilter, 'pve') }}>{t(code.data?.PVE.filter(({ code }) => code === i.pveCde)[0]?.value) || '-'}</td>
                            <td style={{ display: cellStatus(valveFilter, 'near') }}>
                              <SurveyImageTemp
                                activeCategory={activeCategory}
                                siteInfo={siteInfo}
                                src={i.images?.filter((i) => i.imageType === 'NEAR_IMAGE')[0]?.imageThumbUrl || ''}
                                ptNum={i.ptNum}
                                id={i.pointId}
                                typeNum={1}
                                refetchApi={allPointsList}
                                item={i}
                              />
                            </td>
                            <td style={{ display: cellStatus(valveFilter, 'distant') }}>
                              <SurveyImageTemp
                                activeCategory={activeCategory}
                                siteInfo={siteInfo}
                                src={i.images?.filter((i) => i.imageType === 'DISTANT_IMAGE')[0]?.imageThumbUrl || ''}
                                ptNum={i.ptNum}
                                id={i.pointId}
                                typeNum={2}
                                refetchApi={allPointsList}
                                item={i}
                              />
                            </td>
                            <td>{i.ymd}</td>
                          </tr>
                        );
                      } else if (activeTab === 'fires') {
                        //소화전
                        return (
                          <tr key={i.pointId} onClick={() => onCheck(i.pointId)}>
                            <td style={{ width: 70, position: 'sticky', left: 0, zIndex: 20 }}>
                              <Checkbox dark={true} size={18} id={i.pointId} checked={checkList.filter(({ id }) => id === i.pointId)[0].checked} onChange={() => onCheck(i.pointId)} />
                            </td>
                            <td style={{ width: 150, position: 'sticky', left: 70, zIndex: 20 }}>{i.ptNum}</td>
                            <td style={{ display: cellStatus(fireFilter, 'x') }}>{Number(i.x)?.toFixed(3) || '-'}</td>
                            <td style={{ display: cellStatus(fireFilter, 'y') }}>{Number(i.y)?.toFixed(3) || '-'}</td>
                            <td style={{ display: cellStatus(fireFilter, 'z') }}>{Number(i.z)?.toFixed(3) || '-'}</td>
                            <td style={{ display: cellStatus(fireFilter, 'angle') }}>{i.angle || '-'}</td>
                            <td style={{ display: cellStatus(fireFilter, 'diameter') }}>{i.diameter || '-'}</td>
                            <td style={{ display: cellStatus(fireFilter, 'pfr') }}>{t(code.data?.PFR.filter(({ code }) => code === i.pfrCde)[0]?.value) || '-'}</td>
                            <td style={{ display: cellStatus(fireFilter, 'near') }}>
                              <SurveyImageTemp
                                activeCategory={activeCategory}
                                siteInfo={siteInfo}
                                src={i.images?.filter((i) => i.imageType === 'NEAR_IMAGE')[0]?.imageThumbUrl || ''}
                                ptNum={i.ptNum}
                                id={i.pointId}
                                typeNum={1}
                                refetchApi={allPointsList}
                                item={i}
                              />
                            </td>
                            <td style={{ display: cellStatus(fireFilter, 'distant') }}>
                              <SurveyImageTemp
                                activeCategory={activeCategory}
                                siteInfo={siteInfo}
                                src={i.images?.filter((i) => i.imageType === 'DISTANT_IMAGE')[0]?.imageThumbUrl || ''}
                                ptNum={i.ptNum}
                                id={i.pointId}
                                typeNum={2}
                                refetchApi={allPointsList}
                                item={i}
                              />
                            </td>
                            <td>{i.ymd}</td>
                          </tr>
                        );
                      }
                    })}
                  </>
                }
                leftBtnGroup={
                  activeTab === 'points' ? (
                    <>
                      {checkList.filter(({ checked }) => checked).length ? <Button width={80} height={36} fontSize={14} colorType={'greyLineToRed'} text={'선택삭제'} onClick={() => onPopupToggle('selectDel')} /> : null}
                      <Button width={80} height={36} fontSize={14} colorType={'greyLineToRed'} text={'날짜별삭제'} onClick={() => onPopupToggle('dateDel')} />
                    </>
                  ) : null
                }
              />
            ) : (
              <NoData />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SurveyPointTable;
