import React, { useEffect, useState } from 'react';
import { SurveyStyle } from '../../components/templates/_style';
import SurveySiteList from '../../components/templates/survey/SurveySiteList';
import SurveyHeader from 'components/templates/survey/SurveyHeader';
import PopupSurveyMap from 'popups/PopupSurveyMap';
import PopupSurveyDateDel from 'popups/PopupSurveyDateDel';
import PopupSurveySelectDel from 'popups/PopupSurveySelectDel';
import SurveyDataList from '../../components/templates/survey/SurveyDataList';
import { FilterProps } from '../../components/templates/_interface';
import LoadingBar from 'components/atoms/LoadingBar';
import PopupFail from 'components/templates/survey/popup/PopupFail';
import PopupResult from 'components/templates/survey/popup/PopupResult';
import PopupUploadSelect from 'components/templates/survey/popup/PopupUploadSelect';
import { useRecoilState, useRecoilValue } from 'recoil';
import { popupFailState, popupUploadSelect, popupResultState, confirmOneBtn, popupUpload } from 'store/popupAtoms';
import ConfirmOneBtn from 'components/templates/ConfirmOneBtn';

import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Stroke } from 'ol/style.js';
import PopupUpload from 'components/templates/survey/popup/PopupUpload';
import PopupSurveyPipeSelectDel from '../../popups/PopupSurveyPipeSelectDel';
import BasicDragDrop from 'components/templates/drag&drop/BasicDragDrop';
import Fabric from '../../util/photoRegister/ImageTable';
import Ico404 from '../../assets/images/ico-404.svg';

const SurveyDetail = () => {
  const param = useParams();
  const navigate = useNavigate();
  const [confirmOneBtnState, setConfirmOneBtn] = useRecoilState(confirmOneBtn);
  const [popUploadState, setPopUploadState] = useRecoilState(popupUpload);
  const popupUploadSelectState = useRecoilValue(popupUploadSelect);
  const popupFail = useRecoilValue(popupFailState);
  const popupResult = useRecoilValue(popupResultState);

  const [popupStatus, setPopStatus] = useState({
    map: false,
    dateDel: false,
    selectDel: false,
    pipeSelectDel: false,
  });

  // 관종 탭(상수, 오수, 우수,...)
  const [categoryTabList, setCategoryTabList] = useState<FilterProps[]>([]);

  // 관종 내 측점(연결부, 밸브, 맨홀,...) or 관로 테이블 선택
  const [tabList, setTabList] = useState({
    WATER: [
      { name: '전체측점', key: 'points', active: true },
      { name: '연결부', key: 'joints', active: false },
      { name: '맨홀', key: 'holes', active: false },
      { name: '밸브', key: 'valves', active: false },
      { name: '소화전', key: 'fires', active: false },
      { name: '관로', key: 'pipes', active: false },
    ],
    WASTE: [
      { name: '전체측점', key: 'points', active: true },
      { name: '연결부', key: 'joints', active: false },
      { name: '맨홀', key: 'holes', active: false },
      { name: '밸브', key: 'valves', active: false },
      { name: '관로', key: 'pipes', active: false },
    ],
    RAIN: [
      { name: '전체측점', key: 'points', active: true },
      { name: '연결부', key: 'joints', active: false },
      { name: '맨홀', key: 'holes', active: false },
      { name: '밸브', key: 'valves', active: false },
      { name: '관로', key: 'pipes', active: false },
    ],
    ELECTRIC: [
      { name: '전체측점', key: 'points', active: true },
      { name: '연결부', key: 'joints', active: false },
      { name: '맨홀', key: 'holes', active: false },
      { name: '밸브', key: 'valves', active: false },
      { name: '관로', key: 'pipes', active: false },
    ],
    GAS: [
      { name: '전체측점', key: 'points', active: true },
      { name: '연결부', key: 'joints', active: false },
      { name: '맨홀', key: 'holes', active: false },
      { name: '밸브', key: 'valves', active: false },
      { name: '관로', key: 'pipes', active: false },
    ],
  });

  //현재 페이지의 siteId, siteName, 관종 리스트 저장
  const [siteInfo, setSiteInfo] = useState({
    id: '',
    name: '',
    facilityKindList: '',
  });

  //관종류 내에 등록된 측점이 0개인지 체크
  const [firstCall, setFirstCall] = useState(true);

  //팝업 on/off
  const onPopupToggle = (key: string) => {
    setPopStatus({ ...popupStatus, [key]: !popupStatus[key] });
  };

  //목록가기
  const onBack = () => {
    navigate('/survey/list');
  };

  const closeConfirm = () => {
    setConfirmOneBtn((prev) => ({ ...prev, isOpen: false }));
  };

  useEffect(() => {
    setSiteInfo({
      id: param.id || String(sessionStorage.getItem('survey_siteId')),
      name: param.name || String(sessionStorage.getItem('survey_siteName')),
      facilityKindList: param.facilityKindList || String(sessionStorage.getItem('survey_facilityKindList')),
    });
  }, []);
  return (
    <>
      {siteInfo.id ? (
        <SurveyStyle.Contain>
          <SurveyStyle.Inner>
            {/* 공통헤더 */}
            <SurveyHeader onBack={onBack} siteInfo={siteInfo} />
            <SurveyStyle.Wrap style={{ height: 'calc(100% - 84px)' }}>
              <SurveyDataList onPopupToggle={onPopupToggle} siteInfo={siteInfo} categoryTabList={categoryTabList} setCategoryTabList={setCategoryTabList} tabList={tabList} setTabList={setTabList} firstCall={firstCall} setFirstCall={setFirstCall} />
            </SurveyStyle.Wrap>
          </SurveyStyle.Inner>
        </SurveyStyle.Contain>
      ) : null}

      {/*/!* 지도 팝업 *!/*/}
      {popupStatus.map ? <PopupSurveyMap onClose={onPopupToggle} siteInfo={siteInfo} activeCategory={categoryTabList.filter((i) => i.active)[0].key} /> : null}

      {/*/!* 날짜별 삭제 팝업 *!/*/}
      {popupStatus.dateDel ? <PopupSurveyDateDel onClose={onPopupToggle} categoryTabList={categoryTabList} tabList={tabList[categoryTabList.filter(({ active }) => active)[0].key]} siteInfo={siteInfo} /> : null}

      {/*/!* 삭제 팝업 (선택된 항목) *!/*/}
      {popupStatus.selectDel ? <PopupSurveySelectDel onClose={onPopupToggle} siteInfo={siteInfo} activeCategory={categoryTabList.filter((i) => i.active)[0].key} /> : null}

      {/*/!* 파이프 삭제 팝업 (선택된 항목) *!/*/}
      {popupStatus.pipeSelectDel ? <PopupSurveyPipeSelectDel onClose={onPopupToggle} siteInfo={siteInfo} activeCategory={categoryTabList.filter((i) => i.active)[0].key} /> : null}

      {/* 업로드용 팝업 - DragDropBox 포함 */}
      {popUploadState.isOpen && <PopupUpload siteInfo={siteInfo} />}

      {/*/!* 파일업로드 팝업 *!/*/}
      {popupUploadSelectState.isOpen && <PopupUploadSelect siteInfo={siteInfo} setFirstCall={setFirstCall} />}

      {/* 파일 유효성 검사 - 웹에서 체크 결과 */}
      {popupFail.isOpen && <PopupFail />}

      {/* 파일 업로드 결과 팝업 - 성공 or 실패 팝업 */}
      {popupResult.done && <PopupResult />}

      {/* 경고 문구 등 */}
      {confirmOneBtnState.isOpen && <ConfirmOneBtn desc={confirmOneBtnState.desc || ''} onClickCancel={closeConfirm} onClickSubmit={() => {}} />}
      {/* BasicDragDrop 테스트중 */}
      {/* <div style={{ position: 'fixed', zIndex: '999', bottom: 0 }}>
        <BasicDragDrop />
      </div> */}
    </>
  );
};

export default SurveyDetail;
